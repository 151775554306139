const AppDictionary = {
  app: {
    name: "i18next",
  },
  common: {
    comingSoonMsg: "This feature is temporary unavailable.",
    changeVehicle: "Change Vehicle",
    rank: "rank",
    level: "level",
    tomorrow: "tomorrow",
    today: "today",
    yesterday: "yesterday",
    day: "day",
    shortDay: "d",
    and: "and",
    buy: "buy",
    signIn: "Sign In",
    "---": "----------------",
    friends: "Friends",
    random: "Random",
    best: "Best",
    startDuel: "start duel",
    playAlone: "Play Alone",
    comingSoon: "COMING SOON",

    trackOfTheDay: "Track of The Day",
    play: "Play",
    career: "Career",
    allTracks: "All Tracks",
    shop: "Shop",

    topDesigners: "Top Designers",
    duels: "Duels",
    myTracks: "My Tracks",
    createTrack: "Create Track",
    create: "+ create",

    author: "Author",
    complexity: "Complexity",
    avgTimeComplete: "Avg. time to complete",
    fullscreen: "fullscreen",

    "----": "----------------",
    selectOpponent: {
      title: "Select Opponent:",
      rank: "#",
      player: "Player",
      country: "Country",
      time: "Time",
      prize: "Prize",
      winnerPrize: "Prize",
      startDuel: "start duel",
      kickedYourAss: "I kicked your ass",
      inviteToGame: "invite to game",
      inviteToGameBonus:
        "<ol><li>invite friend</li><li>if a friend installs the game, you will get 50 stars</li></ol>",
    },
  },

  book: {
    header: {
      tooltip: {
        addStars: "buy",
        addLives: "add",
        userInfo: "user info",
      },
      news: {
        news1: "Create track for episode <b>Space</b> and get a prize »",
        news2: "<b>Colorize</b> one of the track from first episode »",
      },
    },
    messages: {
      visitDesktopVersion:
        "You can create your own track on a Desktop computer, please visit <br/><br/>http://PaperRacer.com<br/><br/> on your desktop computer to view the track editor.",
      trackOfTheDayBonus:
        "Your track has been chosen as track of the day! You got 1000 bonus stars.",
      verifiedTracksBonus:
        "Congratulation! <br/> $numberOfTracks track(s) have been verified<br/><br/> You've got: ★$starBonus",
      verifiedAvatarsBonus:
        "Congratulation! <br/> $numberOfItems avatar(s) have been verified<br/><br/> You've got: ★$starBonus",
      verifiedVehiclesBonus:
        "Congratulation! <br/> $numberOfItems vehicle(s) have been verified<br/><br/> You've got: ★$starBonus",
      topDesignerBonus:
        "Congratulation you’ve become a Top Designer! You can add as many tracks as you want to the catalog.",
    },
    content: {
      career: {
        selectEpisode: "select episode",
        selectStage: "select stage",

        episodes: [
          {
            episode: "Learning",
            lvl: [
              "Go forward",
              "Loop",
              "Slowdown",
              "Teleport",
              "Boost",
              "Turn Around",
              "Changing gravity",
              "Bombs",
              "Gun",
            ],
          },
          {
            episode: "Beginning",
            lvl: [
              "Bullet Speed",
              "Mountains",
              "Super loop",
              "Blade",
              "Xmas",
              "In a cave",
              "Vs Tank",
              "UFO attack",
              "Think first",
            ],
          },
          {
            episode: "Mountains",
            lvl: [
              "Mountains #1",
              "Mountains #2",
              "Mountains #3",
              "Mountains #4",
              "Mountains #5",
              "Mountains #6",
              "Mountains #7",
              "Mountains #8",
              "Mountains #9",
            ],
          },
          {
            episode: "Winter",
            lvl: [
              "Winter #1",
              "Winter #2",
              "Winter #3",
              "Winter #4",
              "Winter #5",
              "Winter #6",
              "Winter #7",
              "Winter #8",
              "Winter #9",
            ],
          },
          {
            episode: "City",
            lvl: [
              "City #1",
              "City #2",
              "City #3",
              "City #4",
              "City #5",
              "City #6",
              "City #7",
              "City #8",
              "City #9",
            ],
          },
          {
            episode: "Sea",
            lvl: [
              "Sea #1",
              "Sea #2",
              "Sea #3",
              "Sea #4",
              "Sea #5",
              "Sea #6",
              "Sea #7",
              "Sea #8",
              "Sea #9",
            ],
          },
          {
            episode: "UFO attack",
            lvl: [
              "UFO #1",
              "UFO #2",
              "UFO #3",
              "UFO #4",
              "UFO #5",
              "UFO #6",
              "UFO #7",
              "UFO #8",
              "UFO #9",
            ],
          },
          {
            episode: "Space",
            lvl: [
              "Space #1",
              "Space #2",
              "Space #3",
              "Space #4",
              "Space #5",
              "Space #6",
              "Space #7",
              "Space #8",
              "Space #9",
            ],
          },
          {
            episode: "Hard",
            lvl: [
              "Hard #1",
              "Hard #2",
              "Hard #3",
              "Hard #4",
              "Hard #5",
              "Hard #6",
              "Hard #7",
              "Hard #8",
              "Hard #9",
            ],
          },
        ],
      },
      promoEpisode: {
        lvl: [
          "Halloween 1",
          "Halloween 2",
          "Halloween 3",
          "Halloween 4",
          "Halloween 5",
          "Halloween 6",
          "Halloween 7",
          "Halloween 8",
          "Halloween 9",
        ],
      },
      catalog: {
        likes: "most popular",
        finished: "most completed",
        id_track: "by adding",
        "---": "----------------",
        today: "today's",
        thisWeek: "this week",
        thisMonth: "this month",
        allTheTime: "all the time",
      },
      shop: {
        feature_subscription: "Unlimited lives for",
        days: "days",
        feature_decreaseLiveRefillWaitingTime:
          "Decrease life refill waiting time",
        feature_increaseNumberOfMaxLives: "Increase number of max lives",
        feature_liveRefill: "Increase current lives <br/>",
        temporarilyImprovement: "temporary improvement ↑",
        constantImprovement: "permanent improvement ↓",

        vehicle_motocross: "Motocross",
        vehicle_quadrobike: "ATV",
        vehicle_bigfoot: "Bigfoot",
        vehicle_sportbike: "SportBike",
        vehicle_lunarrover: "LunarRover",
        vehicle_sportcar: "Sport Car",

        livesStore: "Live Store",
        avatarStore: "Avatar Store",
        vehicleStore: "Vehicle Store",
        starStore: "Star Store",

        sorryYouCantBuyStars:
          "Star purchase service is not available anymore, however, you can keep earning stars for free in Daily Missions.",
      },
      userAccount: {
        myAccount: "My Profile",
        myTracks: "My Tracks",
        myStuff: "My Stuff",
        myInfo: "My Info",
        myAvatars: "My Avatars",
        myVehicles: "My Vehicles",
      },
      userInfo: {
        availableAvatars: "Available avatars",
        achievements: "Achievements",
        currentLives: "Current lives",
        maxLives: "Max number lives",
        liveRecoveringTime: "Recovering time",
      },
      duels: {
        all: "all",
        completed: "completed",
        "--------------": "--------------------",
        desc: "Icons:",
        win: "you’ve won the duel",
        fail: "fail",
        new: "new duel",
        even: "even",
        waiting: "waiting for opponent",
        youDontHaveDuels1:
          "You don't have duels pending. You can select an opponent at the beginning of each catalog track.",
        youDontHaveDuels2: "You can start a duel in any of these sections.",
      },
      myTrack: {
        youMustCompleteTrack:
          "You must complete this track to make it available for other users in the Catalog",
        areYouSureToDelete: "Are you sure you want to delete this track?",
        youDontHaveRank:
          "To add this track to the catalog, your rank should be atleast $minRankToPublish. Then your track will be available for other players to play.",
        publishTrackToCatalogTooltip:
          "Publishing this track to the catalog will make it available to other users.",
        publishTrackToCatalogPopup:
          "<div class='publishTrackToCatalogPopup'><h1>Do you want to publish your track to the catalog?</h1> To publish your track to catalog you need to pay deposit of <i class='starsIcon'>$publishToCatalogPrice</i>.<br/> If the track is verified we will return the deposit of <i class='starsIcon'>$starBonus</i> to your account. If you add a popular and well designed track, you can become a Top Designer. This will give you the ability to add new tracks to catalog without any deposits or waiting time.</div>",
        publishTrackToCatalogPopupButton:
          "Publish Track ★$publishToCatalogPrice",
        publishYouDontHaveEnoughStars:
          "To publish the track to the catalog you need to pay a deposit of $publishToCatalogPrice stars. You don't currently have enough stars for this deposit.",
        youTrackIsOnReview:
          "Your track is being reviewed by our Moderators and other testers",
        youCanShareYourTrack: "You can share your track now.",
        yourTrackHasBeenVerified:
          "Congratulations! Your track has been verified and is available in the catalog",
        yourTrackHasBeenBanned1: "Your track has been banned.",
        areYouSureToUnPublish: "Are you sure you want to unpublish this track?",
        wait24h: "You can add the track to the catalog in $timeToWait",
      },
      trackOfTheDay: {
        startPlay: "Play",
      },
      popups: {
        areYouSureBuyStarsPopup: "Are you sure you want to purchase stars?",
        yourInvitesAccepted: "Your invite has been accepted!",
        yourBonus: "Your bonus:",
        dailyBonusTitle: "Daily bonus",
        dailyBonusText:
          "Comeback to this game everyday to receive a daily bonus. Today you've got:",
        sorryDontEnoughStars:
          "Sorry, you don't have enough stars. You can purchase more stars in the Star Shop.",
        dailyMissionTitle: "Earn Stars in Daily missions",
        dailyMission1: "Enter the game",
        dailyMission2: "Complete the Track of The Day",
        dailyMission3: "Win 5 duels against friends",
        dailyMission4: "Complete 10 new tracks",
        dailyMission5: "Create a Track Of The Day",
        dailyMissionSubTitle: "Miscellaneous",
        dailyMissionOutOfLifeMessage:
          "Out of lives? Don't worry! You only need lives in Career Mode. You can also earn stars in Daily missions.",
        dailyMissionText1:
          "In this game section, you can see all possible duels. Everyday you can earn up to 25 stars by winning duels against your friends. You can also win an unlimited number of stars by winning duels with top placing users.",
        dailyMissionText2:
          "In this game mode, you can find the best tracks created by the top designers. Each track has a XP number, ХР - stands for eXPerience. More challenging tracks have higher XP numbers. As soon as you get 100 XP, you will be moved on to the next rank and gain 10 bonus stars. This means you can earn an unlimited number of stars by completing tracks and earning XP.",
        dailyMissionText3:
          "In this game mode, you can find all the tracks created by various users. Each track has a XP number, ХР - stands for eXPerience, more challenging tracks have higher XP numbers. As soon as you get 100 XP, you will be moved on to the next rank and gain 10 bonus stars. This means you can earn an unlimited number of stars by completing tracks and earning XP.",
        areYouConfirmingPurchase: "Are you sure you want purchase this?",
      },
    },
    navigation: {
      back: "Back",
      earn: "Earn stars",
      backToWelcomeScreen: "To Homepage",
      selectEpisode: "Select Episode",
      selectShop: "Select Shop",
      backToMyTracks: "Back to My Tracks",
      backToUserInfo: "Back to Info",
      backToCatalog: "Back to Amateur Tracks",
      buyStars: "Buy Stars",
    },
  },

  "#######################": "#######################",

  game: {
    common: {
      playAgain: "Play Again",
      backToCatalog: "Back to Catalog",
      backToMenu: "Back to Menu",
      pressAnyKey: "Press any key to continue",
      goToCatalog: "Go to Catalog",
      goToTopDesigners: "Go to Best Designers",
      goToCareer: "Back to Career",
      rateTrack: "Rate Track",
      goBack: "Go Back",
      "---------------": "----------------",
      starsOnAccount: "You have:",
      markStageAsCompleted: "Mark track as completed",
      buyUnlimitedLives: "Unlimited lives for 1 day",
      buy1Live: "Buy 1 life",
      buy5Lives: "Buy 5 lives",
      buyRemoveLives1Week: "1 week without Ads",
      moreInTheShop: "More in the shop",
      backToDuels: "Back to Duels",
      selectOpponent: "Select opponent",
      changeVehicleWarning:
        "Warning! New selected vehicle sometimes can't fit track!",
      "----------------": "----------------",
      trackCompleted: "Stage Completed",
      tableOfResults: "Table of Results",
      championshipTable: "Championship Table",
    },

    menu: {
      continue: "Continue",
      rules: "Rules",
      exit: "Exit",
      buy: "buy",
      markAsCompleted: "Mark as completed",
      showHowCompleted: "Show how to complete",
      recover: "recover",
      noVehicleAvailable1: "You don't have any available vehicles.",
      noVehicleAvailable2: "You can buy new vehicles at the shop.",
      "-------------": "----------------",
      fromCheckPoint: "From Checkpoint",
      fromCheckPointHotKey: "Hotkey - Enter",
      fromPrevCheckPoint: "From Previous Checkpoint",
      fromPrevCheckPointHotKey: "Hotkey - Backspace",
      "--------------": "----------------",
      rateThisTrack: "Rate this track",
      like: "Good",
      dislike: "Bad",
      report: "Flag",
    },

    failScreen: {
      otherThings1: "No lives? You are still able to play!",
      otherThings2: "You need lives to play in Career Mode.",
      otherThings3: "",
      otherThings4: "You can play to earn more stars",
      otherThings5: "in other game sections.",
    },

    duel: {
      youWinTitle: "You won!",
      youLoseTitle: "You lose!",
      youEvenTitle: "Tie!",
    },

    createTrack: {
      selectName: "Enter your track’s name",
      selectThumb:
        "Drag and zoom to find and select an interesting place on the track. This will be featured on the track's thumbnail",
      trackNamePlaceholder: "Name of the track",
      thumbIsEmpty:
        "Thumbnail is empty! Select part of the track to show as the thumbnail!",
      trackNameTooShort: "Track name is too short.",
      importTrack: "Import Track",
      backToTrackCreating: "Back to Track Creation",
      changeWheels: "Change Vehicle",
      import: "import",
      exitConfirm: "Are you sure? All your changes will be lost!",
      teleportConfirm:
        "Warning! Some teleports don't have valid enter/exit points. Are you sure you want to save the track without these teleports?",

      "--------------": "--------------------",

      paintbrush: "hatching",
      eraser: "eraser",
      bomb: "bomb",
      goal: "checkpoint",
      gun: "gun",
      boost: "boost",
      gravity: "gravity",
      slowDownPoint: "slowdown point",
      camera: "camera",
      scenery: "background",
      track: "track",
      straightLine: "straight line",
      curve: "curve line",
      teleportEnter: "teleport enter",
      teleportExit: "teleport exit",

      "-------------": "--------------------",

      rectangle: "Rectangle",
      square: "Square",
      circle: "Circle",
      hatchingType: "Hatching type:",
      horizontal: "horizontal",
      vertical: "vertical",
      crossing: "crossing",
      chaotic: "chaotic",
      shapeSize: "Shape size",
      lineWidth: "Line width",
      stepSize: "Step size",
      stepNoise: "Step noise",
      silhouetteNoise: "Silhouette noise",
      eraserSize: "Eraser size",

      teleportMenu: "Teleport Menu",
      addTeleport: "Add New Teleport",
    },
  },

  "######################": "#######################",

  guideline: {
    common: {
      nextLabel: "Next →",
      prevLabel: "← Back",
      skipLabel: "Exit tutorial",
      doneLabel: "Back to Game",
      closeLabel: "Exit",
    },
    book: {
      doneLabel: "Go to Career",
      userStars:
        "Your stars are shown here <br/>Stars - are the game currency.<br/><br/>",
      career: "Start from career, there is tutorial here!",
    },
    game: {
      career: {
        stage1Text:
          "To complete this level, you have to touch the star located at the end of the track. To move forward you need to hold the 'Up' button or the 'Y' if you are using a touch device. <div style='height: 90px; width: 100%; text-align: center;'><img src='$img' style='margin: 0 auto;'/></div>",
        stage2Text:
          "To touch the star which is located at the top of the loop, you must hold the ‘Up’ button or ‘Y’ button (on touch devices). Don’t release this button while trying to go up the loop. If your speed is fast enough, you will be riding on the ceiling. <div style='height: 123px; width: 100%; text-align: center;'><img src='$img' style='margin: 0 auto;'></div>",
        stage3Text:
          "To jump to the top, you need to gain speed by holding the Up button and then land on two wheels. You can balance in the air using the left and right buttons <div style='height: 90px; width: 100%; text-align: center;'><img src='$img' style='margin: 0 auto;'></div>",
        stage4Text:
          "To complete this level you need to turn around by pressing Z button or Spacebar and touch all of the stars on this track. <div style='height: 149px; width: 100%; text-align: center;'><img src='/img/guideline/4.png' style='margin: 0 auto;'></div>",
        stage5Text:
          "Hint: You can balance your rider in the air without releasing the Up button.  <div style='height: 90px; width: 100%; text-align: center;'><img src='/img/guideline/5.gif' style='margin: 0 auto;'></div>",
        stage6Text:
          "To complete this level you should fall down to the bottom of the pit without touching bombs.",
        stage8Text:
          "To complete this level you need to fly over the barrier using the reverse gravity objects. <div style='height: 173px;padding-top: 5px; width: 100%; text-align: center;'><img src='/img/guideline/8.png' style='margin: 0 auto;'></div>",
        stage18Text:
          "Remember, to turn around You need to press SPACEBAR or Z <div style='height: 149px; width: 100%; text-align: center;'><img src='/img/guideline/4.png' style='margin: 0 auto;'></div>",
        "-------------": "----------------",
        looseLives:
          "Here you can see your life recovery time, <br/><br/>The first number after the heart icon is the number of lives you currently have, <br/>The second number is the max amount of lives that you can have",
        restartHotKeys:
          "<div style='width: 435px;'>You can restart using the following hotkeys: <br/><br/> <img src='/img/guideline/enter.png'  style='margin-bottom: -5px;'/> - restart from checkpoint<br/><br/><img src='/img/guideline/backspace.png' style='margin-bottom: -5px;'/> - restart from previous checkpoint</div>",
        checkpointsCompleted:
          "To finish the track, you need to collect all the stars. <br/><br/>The first number is the amount of stars you have collected, <br/>the second number is total amount of stars on this track. <br/>Collected stars move to your account as currency if you collect all of the stars on the track.",
      },
      catalog: {
        checkpointsCompleted:
          "To finish this track, you need to pass all checkpoints.<br/><br/> The first number is the amount of passed checkpoints<br/> the second number is the total number of checkpoints on the track,<br/> the next number is time spent on the track",
        likeFlagButtons:
          "Here you can vote for the track<br/><br/>thumb up - you liked the track (like)<br/> thumb down - you didn’t like the track. (dislike)<br/>the red flag - the track is abusive and must be removed.",
      },
      create: {
        welcomeAnonymous:
          "Unauthorized users can't save tracks, but you can still create them and try to play on them yourself. Note: Nobody else can play on your track in this case.  <br/><br/> Start learning how to create your own tracks.",
        exitAnonymous:
          "Unauthorized users can't save track, please log in to have ability to save created track.",
        camera:
          "If you select this object, hold left mouse button and start dragging around the screen. This allows you change current position of the screen on the track",
        brush:
          "Choosing this object and pressing the left mouse button will allow you to draw curved lines",
        line: "Choosing this object and pressing the left mouse button will allow you to draw a straight line",
        paintbrush:
          "With this object, you can fill large spaces on track or background.",
        eraser: "You can remove any lines with the eraser",
        colorSelector: "Here you can select colors for your track’s lines",
        settings:
          "Here you can change the vehicles which can be used on your track",
        save: "Save track. You will be prompted to give your track a name on the next step.",
        exit: "Exit without saving",
        checkPoint: "Checkpoint, saving points",
        acceleration:
          "Speedup. By pressing and turning the cursor of the mouse you can change the direction of the speedup object.",
        gravity:
          "Change the direction of gravity. by pressing and turning the cursor you can change the direction of the gravity object",
        slowPoint: "Slowdown point",
        bomb: "If the player touches this object, they will die.",
        teleport:
          "With the Teleport, a racer will enter in one place and exit in another. You can add as many teleports as you want to your track.",
        gun: "A gun shoots bullets in intervals. You can set direction of the gun fire.",
        background:
          "Here you can change the depth of the background you are drawing on. The farther the background is, the slower it moves towards the stage",
        brushSize: "You can change size of brush.",
        goDraw: "Go Draw",
      },
    },
  },
};

export default AppDictionary;

import React, { useContext /* useEffect */ } from "react";
import { AppContext } from "../../context";

const YoutubeWritingCorrection = () => {
  const [, appDispatch] = useContext(AppContext);
  return (
    <div
      className="overlay"
      onClick={() => {
        appDispatch({
          popup: "",
        });
      }}
      onKeyPress={() => {
        appDispatch({
          popup: "",
        });
      }}
      role="button"
      tabIndex={0}
    >
      <iframe
        id="videoWritingCorrectionPopup"
        width="850"
        height="480"
        title="Vocabularying"
        src="https://www.youtube.com/embed/nibcZvhjJEQ?autoplay=1"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  );
};

export default YoutubeWritingCorrection;

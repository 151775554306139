import React, { useEffect, lazy, Suspense } from "react";
import {
  BrowserRouter,
  HashRouter,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
import App from "./App";
import { AppProvider } from "../context";
import Login from "./app/Login";
import { getJWT } from "../utils";
//const TestApp = lazy(() => import("./tests/TestApp"));

// function PrivateRoute({ component: Component, ...rest }) {
//   const isLoggedIn =
//     window.location.host === "192.168.1.3:5001" ||
//     window.location.host === "192.168.1.4:5001"
//       ? true
//       : getJWT();

//   console.log("isLoggedIn -->", isLoggedIn);
//   return (
//     <Route
//       {...rest}
//       render={(props) =>
//         isLoggedIn ? (
//           <Component {...props} />
//         ) : (
//           <Redirect
//             to={{
//               pathname: "/login",
//               state: { from: props.location.pathname },
//             }}
//           />
//         )
//       }
//     />
//   );
// }

function Router() {
  const Router = !window.cordova ? BrowserRouter : HashRouter;

  return (
    <AppProvider>
      <Router>
        <Switch>
          <Route path="/" component={App} />
          {/* <Route path="/catalog" component={App} />
          <Route path="/userInfo" component={App} />
          <Route path="/catalog/my-writings/:url" component={App} />
          <Route path="/login" component={Login} />
          {/* <PrivateRoute path="/test/" component={TestRouting} /> 
          <PrivateRoute path="/" component={App} /> */}
        </Switch>
      </Router>
    </AppProvider>
  );
}

// function TestRouting() {
//   usePageView();

//   return (
//     <Suspense fallback={<div>Loading...</div>}>
//       <Switch>
//         <Route path="/test/:testType/:testId" render={(props) => <TestApp {...props} />} />
//         <Route path="/test/">
//           <ComingSoon />
//         </Route>
//       </Switch>
//     </Suspense>
//   );
// }

export default Router;

import { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import React from "react";
import { AppContext } from "../../context";
import Link from "../parts/Link";

const IELTSVocabularies = () => {
  const history = useHistory();
  return (
    <div className="welcomeScreen">
      <div className="landingScreen">
        <h1>
          IELTS <b>Vocabularies</b>
        </h1>
        <style>
          {`
           .vocabularies  {
            display: flex;
            justify-content: space-around;
            margin-top: 12px;
            letter-spacing: 1px;
           }
    .vocabularies li {
        margin-top: 30px;
    }
    .vocabularies li a{
      text-decoration: none;
      color: #000;
      box-shadow: 0px 3px 4px 0px rgba(0,0,0,0.2), 0px -1px 4px 0px rgba(0,0,0,0.1), 0px 1px 3px 0px rgba(0,0,0,0.12);
      display: inline-block;
      padding: 20px;
      border-radius: 5px;
      transition: all .1s ease-in;
      position: relative;
  }
  .vocabularies li a:hover{
    transform:  scale(1.2);
  }

    .vocabularies li a img{
        width: 75px;
    }
    .vocabularies li a b{
        display: block;
        font-size: 29px;
        padding: 5px 0;
    }

    @media screen and (max-width: 800px) {
        .vocabularies  {
            flex-direction: column;
        }

        h1 b {
            display: none;
        }

    }
    .premiumBadge{
      background-color: rgb(245, 0, 87); 
      clip-path: polygon(0px 0px, 100% 100%, 100% 100%, 100% 0%); 
      width: 62px; 
      height: 62px; 
      position: absolute; 
      top: 0px; 
      right: 0px;
      color: #fff;
    }

    .premiumBadge svg{
      position: absolute; top: 0px; right: 0px; color: rgb(255, 255, 255);
      width: 35px
    }
        `}
        </style>
        <div>
          <ul className="vocabularies">
            <li>
              <Link href={"/ielts-vocabulary/5"}>
                <img src="/static/dictionary.png" />
                <b>Band 5</b>
                <i>300 words</i>
              </Link>
            </li>
            <li>
              <Link href={"/ielts-vocabulary/6"}>
                <img src="/static/dictionary.png" />
                <b>Band 6</b>
                <i>500 words</i>
              </Link>
            </li>
            <li>
              <Link href={"/ielts-vocabulary/7"}>
                <div className="premiumBadge">
                  <svg
                    class="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge"
                    focusable="false"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    fill="currentColor"
                  >
                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                  </svg>
                </div>
                <img src="/static/dictionary.png" />
                <b>Band 7</b>
                <i>900 words</i>
              </Link>
            </li>
            <li>
              <Link href={"/ielts-vocabulary/8"}>
                <div className="premiumBadge">
                  <svg
                    class="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge"
                    focusable="false"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    fill="currentColor"
                  >
                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                  </svg>
                </div>
                <img src="/static/dictionary.png" />
                <b>Band 8</b>
                <i>1400 words</i>
              </Link>
            </li>
            <li>
              <Link href={"/ielts-vocabulary/9"}>
                <div className="premiumBadge">
                  <svg
                    class="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge"
                    focusable="false"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    fill="currentColor"
                  >
                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                  </svg>
                </div>
                <img src="/static/dictionary.png" />
                <b>Band 9</b>
                <i>2000 words</i>
              </Link>
            </li>
          </ul>

          <div
            style={{
              textAlign: "left",
              marginTop: "50px",
              marginBottom: "30px",
              fontSize: "21px",
              lineHeight: "28px",
              padding: "32px",
              backgroundColor: "#eaeaea",
              borderRadius: "20px",
              letterSpacing: "1px",
            }}
          >
            In IELTS Listening part of the exam, you will need to rely on
            synonyms or similar expressions to find the correct answer. This is
            particularly true in summary and note completion questions. Where
            you have some sentences and need to fill in the words from the
            listening. The words in your completion exercise will have synonyms
            or different wording, while the word you need to fill in will be the
            exact one of the listening.
            <br />
            <br />
            When answering the questions in your IELTS reading, paraphrasing
            will be necessary, as they will never use the same words from the
            text in your questions. The questions will always use synonyms or
            paraphrase parts of the text to say the same thing. If you do not
            know how to look for similar words or expressions in the text you
            will never find the answer. As a result, your reading score will go
            down as you either waste too much time finding the answer and/or do
            not find the answer at all.
          </div>
        </div>
      </div>
    </div>
  );
};
export default IELTSVocabularies;

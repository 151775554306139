import { useContext } from "react";
import { AppContext } from "../../context";

const Header = () => {
  const [appState, appDispatch] = useContext(AppContext);
  return (
    <>
      <div
        onClick={() => {
          appDispatch({ isDrawerOpen: !appState.isDrawerOpen });
        }}
        class={appState.isDrawerOpen ? "menu-btn-1 active" : "menu-btn-1"}
      >
        <span></span>
      </div>
    </>
  );
};

export default Header;

const goToLink = (path) => {
  if (
    window.location.host === "ielts69.com" ||
    window.location.host === "www.ielts69.com"
  ) {
    window.location.href = `https://app.ielts69.com${path}`;
  } else if (typeof window !== "undefined" && window.cordova) {
    window.history.replaceState(null, "", `#${path}`);
    window.dispatchEvent(new HashChangeEvent("hashchange"));
  } else {
    window.history.replaceState(null, "", path);
    dispatchEvent(new PopStateEvent("popstate", { state: null }));
  }
};

const openLink = (url) => {
  if (window.cordova) {
    // eslint-disable-next-line
    cordova.InAppBrowser.open(url, "_system", "location=no,fullscreen=yes");
  } else {
    window.open(url, "_blank").focus();
  }
};

const sleep = async (ms) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export { goToLink, openLink, sleep };

import { useHistory } from "react-router-dom";
const Link = ({ href, className, children }) => {
  const history = useHistory();

  return (
    <a
      href={href}
      onClick={(e) => {
        e.preventDefault();
        history.push(href);
      }}
      className={className}
    >
      {children}
    </a>
  );
};

export default Link;

/*global iwin */
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import axios from "../../axios";
import AppDictionary from "../../translation";
import {
  formatNumber,
  getAvatarById,
  getPathById,
  isLevelCompleted,
  settings,
  staticServerURL,
  transliterateString,
} from "../../utils";
import { AppContext } from "../../context";
import Pagination from "../parts/Pagination";
import Link from "../parts/Link";
import TrackThumb from "../parts/TrackThumb";

const trackMock = {
  id: 1234567,
  trackTitle: "",
  status: 3,
  xpBonus: 18,
  started: 64231,
  finished: 4423,
  fails: 1138168,
  totalTimeSpent: 945198,
  totalResultsTime: 332752,
  likes: 1004,
  dislikes: 736,
  stars: 5,
  createdAt: "2016-12-04T19:42:52.000Z",
  updateAt: "2016-12-04T19:42:52.000Z",
  userId: 23740205,
  uid: 23740205,
  track_title: "",
  id_track: 1234567,
  username: "",
};

const Tracks = new Array(9).fill(null).map(() => trackMock);

const UserInfo = {
  uid: 24186006,
  username: "",
  country: "RU",
  avatar: 202,
  topDesigner: "1",
};

const TopDesigners = [
  {
    username: "",
    avatar: 202,
    uid: 23610222,
    likes_sum: "56092",
    tracks: new Array(3).fill(null).map(() => trackMock),
  },
  {
    username: "",
    avatar: 202,
    uid: 113712,
    likes_sum: "53630",
    tracks: new Array(3).fill(null).map(() => trackMock),
  },
  {
    username: "",
    avatar: 202,
    uid: 24186006,
    likes_sum: "42654",
    tracks: new Array(3).fill(null).map(() => trackMock),
  },
];

const Catalog = ({ type }) => {
  const [appState, appDispatch] = React.useContext(AppContext);
  const history = useHistory();
  const staticServer = "";

  const [completedStages, setCompletedStages] = React.useState([
    1, 2, 3, 4, 5, 6, 7, 8, 9,
  ]);

  const [data, setData] = React.useState({ Tracks, UserInfo, TopDesigners }); // UserInfo for user designer page
  const { uid, page = 1 } = useParams();

  let nextStep = "play";
  const showAds = false;
  if (showAds) {
    nextStep = "adTrack";
  }

  const levels = [
    {
      trackId: 305556,
      trackName: "Learning",
    },
    {
      trackId: 222525,
      trackName: "Beginning",
    },
    {
      trackId: 228229,
      trackName: "Mountains",
    },
    {
      trackId: 450543,
      trackName: "Winter",
    },
    {
      trackId: 209983,
      trackName: "City",
    },
    {
      trackId: 317027,
      trackName: "Sea",
    },
    {
      trackId: 480726,
      trackName: "UFO attack",
    },
    {
      trackId: 212957,
      trackName: "Space",
    },
    {
      trackId: 480937,
      trackName: "Hard",
    },
  ];

  React.useEffect(() => {
    async function fetchData() {
      let data;
      if (type === "career") {
        const req = await axios.get(`/tracks/select-stage/${page}`);
        data = req.data;
        const { Tracks, Completed } = data;

        if (appState.userInfo.id !== 1) {
          setCompletedStages(Completed);
        }

        if (Tracks) {
          console.log("data=====>", req.data);

          settings.currentEpisode = page;
          settings.currentEpisodeNotCompletedStageArr = [];
          settings.trackIdStageNumberArr = [];
          for (var i = 0; i < Tracks.length; i++) {
            var level = i + (parseInt(page) - 1) * 9 + 1;
            var stage = i + 1;
            if (Completed.indexOf(level) === -1) {
              settings.currentEpisodeNotCompletedStageArr.push(Tracks[i]);
              settings.trackIdStageNumberArr[Tracks[i]] = stage;
            }
          }
        }
      } else if (type === "catalog") {
        const req = await axios.get(`/tracks/catalog/${page}`);
        data = req.data;
      } else if (type === "designer") {
        const req = await axios.get(`/tracks/user/${uid}/${page}`);
        data = req.data;
      } else if (type === "top-designers") {
        const req = await axios.get(`/tracks/top-designers/${page}`);
        data = req.data;
      }
      setData({
        Tracks,
        UserInfo,
        TopDesigners,
        ...data,
      });
    }

    setData({
      Tracks,
      UserInfo,
      TopDesigners,
    });
    fetchData();
  }, [page, type, uid]);

  // console.log("Tracks=============>", data?.Tracks);
  // console.log("UserInfo=============>", data.UserInfo);
  // console.log("TopDesigners=============>", data.TopDesigners);

  console.log("completedStages===>", completedStages);

  return (
    <div className="trackList">
      <style>{`
       
        .designerTitle {
          font-size: 30px;
          padding-top: 10px;
          padding-bottom: 10px;
        }
        .designerTitle a{
          text-decoration: none;
          color: #000;
        }
        .designerTitle a:hover{
          text-decoration: underline;
        }

      `}</style>

      {type === "selectLevel" && (
        <div className="career" key={`${type}_${page}`}>
          <h1>select episode</h1>
          <ul>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((level) => {
              return (
                <li className="trackThumb">
                  <Link href={`/career/${level}`}>
                    <TrackThumb
                      trackId={levels[level - 1].trackId}
                      key={levels[level - 1].trackId}
                    />
                    <b>{levels[level - 1].trackName}</b>
                  </Link>
                  {isLevelCompleted(level - 1, completedStages) && (
                    <i className="completed" />
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      )}
      {data && type === "career" && (
        <div key={`${type}_${page}`} className="careerTrackList">
          <h1>select stage</h1>
          <ul>
            {data.Tracks.map((trackId, index) => {
              const episode = page;
              const level = index + 1 + (page - 1) * 9;
              const stage = index + 1;

              return (
                <li className="trackThumb">
                  <a
                    rel="nofollow"
                    href={`/${nextStep}/${trackId}/${episode}-${stage}: ${
                      AppDictionary.book.content.career.episodes[episode - 1]
                        .lvl[index]
                    }`}
                    className="background"
                  >
                    <TrackThumb trackId={trackId} key={trackId} />
                    {completedStages.includes(level) && (
                      <i className="completed" />
                    )}
                    <b>
                      {
                        AppDictionary.book.content.career.episodes[page - 1]
                          .lvl[index]
                      }
                    </b>
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      )}

      {data && type === "catalog" && (
        <div key={`${type}_${page}`}>
          <h1 className="up">all tracks</h1>
          <ul className="cataloging">
            {data.Tracks.map((track, index) => {
              return (
                <li className="trackThumb">
                  <div class="stat">
                    <span class="likes">{formatNumber(track.likes)}</span>
                    <span class="played">{formatNumber(track.started)}</span>
                    <span class="dislikes">{formatNumber(track.dislikes)}</span>
                  </div>
                  <Link
                    href={`/${nextStep}/${track.id}`}
                    className="background"
                  >
                    <TrackThumb trackId={track.id} key={track.id} />
                    {/* <% if ($.inArray(level, data.Completed) !== -1) {%>
              <i className="completed"></i>
          <% } %> */}
                  </Link>

                  <b className="trackTitle">
                    {transliterateString(track.trackTitle)}
                  </b>

                  <Link href={`/user/${track.uid}`} className="author">
                    Author: {transliterateString(track.username)}
                  </Link>
                </li>
              );
            })}
          </ul>

          <Pagination pagingInfo={data.PagingInfo} url="/catalog" />
        </div>
      )}

      {data && type === "designer" && (
        <div key={`${type}_${page}`}>
          {data.UserInfo && (
            <div className="designerTitle">
              <img
                src={getAvatarById(data.UserInfo.avatar)}
                className="avatar"
              />
              {transliterateString(data.UserInfo.username)}
            </div>
          )}
          <ul className="cataloging">
            {data.Tracks.map((track, index) => {
              return (
                <li className="trackThumb">
                  <div class="stat">
                    <span class="likes">{formatNumber(track.likes)}</span>
                    <span class="played">{formatNumber(track.started)}</span>
                    <span class="dislikes">{formatNumber(track.dislikes)}</span>
                  </div>
                  <Link
                    href={`/${nextStep}/${track.id}`}
                    className="background"
                  >
                    <TrackThumb trackId={track.id} key={track.id} />
                    {/* <% if ($.inArray(level, data.Completed) !== -1) {%>
              <i className="completed"></i>
          <% } %> */}
                  </Link>

                  <b>{transliterateString(track.trackTitle)}</b>
                </li>
              );
            })}
          </ul>
          <Pagination
            pagingInfo={data.PagingInfo}
            url={type === "designer" ? `/user/${uid}` : `/${type}`}
          />
        </div>
      )}

      {data && type === "top-designers" && (
        <div key={`${type}_${page}`}>
          <h1 className="up">top designers</h1>
          {data.TopDesigners.map((designer) => {
            return (
              <>
                <div className="designerTitle">
                  <Link href={`/user/${designer.uid}`}>
                    <span></span>
                    <img
                      src={getAvatarById(designer.avatar)}
                      className="avatar"
                    />
                    {transliterateString(designer.username)}
                  </Link>
                </div>

                <ul className="cataloging">
                  {designer.tracks.map((track, index) => {
                    return (
                      <li className="trackThumb">
                        <div class="stat">
                          <span class="likes">{formatNumber(track.likes)}</span>
                          <span class="played">
                            {formatNumber(track.started)}
                          </span>
                          <span class="dislikes">
                            {formatNumber(track.dislikes)}
                          </span>
                        </div>
                        <Link
                          href={`/${nextStep}/${track.id}`}
                          className="background"
                        >
                          <TrackThumb trackId={track.id} key={track.id} />
                        </Link>

                        <b>{transliterateString(track.trackTitle)}</b>
                      </li>
                    );
                  })}
                </ul>
              </>
            );
          })}
          <Pagination pagingInfo={data?.PagingInfo} url={`/top-designers`} />
        </div>
      )}
      <br />
    </div>
  );
};

export default Catalog;

import Link from "./Link";

const Pagination = ({ pagingInfo, url }) => {
  if (!pagingInfo) {
    return <></>;
  }

  const { current_page, total_pages } = pagingInfo;

  //   "current_page": 4,
  //   "total_pages": 234,
  //   "total_records": "2114",
  //   "records_per_page": 9

  var sp; /* start page */

  var max = 5; /*  1 ... 1 2 3 4 5 ... 7 */

  var lp; /* last page */

  if (current_page < max) {
    sp = 1;
  } else if (current_page >= total_pages - Math.floor(max / 2)) {
    sp = total_pages - max + 1;
  } else if (current_page >= max) {
    sp = current_page - Math.floor(max / 2);
  }

  var widerPageLimit = 99;

  const pages_center = [];

  for (var i = sp; i <= sp + max - 1; i++) {
    if (i > total_pages) continue;

    lp = i;
    var isWider = i > widerPageLimit ? "Wider" : "Normal";

    pages_center.push(
      <Link
        href={`${url}/${i}`}
        className={i === current_page ? `selected${isWider}` : `${isWider}`}
      >
        {i}
      </Link>
    );
  }

  return (
    total_pages > 0 && (
      <div className="pagination">
        <style>{`
        .pagination{
            line-height: 37px;
            margin: 35px auto 50px;
        }
            .pagination a {
                padding: 7px 12px 4px;
                margin: 0 5px;
                border: 1px #000 solid;
                color: #000;
                border-radius: 6px;
                text-decoration: none;
            }
            .pagination a:hover {
              background-color: #eee;
            }
            .pagination a.selectedWider,
            .pagination a.selectedNormal{
                background-color: #000;
                color: #fff;
            }
            .pagination div{
                padding: 7px 1px 4px;
                margin: 0 5px;
                display: inline-block;
                font-family: Arial;
                font-size: 18px;
            }
        `}</style>
        {current_page > 1 && (
          <Link href={`${url}/${current_page - 1}`} className="Normal">
            &larr;
          </Link>
        )}
        {current_page >= max && (
          <>
            <Link href={`${url}/1`} className="Normal">
              1
            </Link>
            <div>...</div>
          </>
        )}
        {pages_center}

        {lp !== total_pages && (
          <>
            <div>...</div>
            <Link
              href={`${url}/${total_pages}`}
              className={total_pages > widerPageLimit ? "Wider" : "Normal"}
            >
              {total_pages}
            </Link>
          </>
        )}

        {current_page < total_pages && (
          <Link href={`${url}/${current_page + 1}`} className="Normal">
            &rarr;
          </Link>
        )}
      </div>
    )
  );
};

export default Pagination;

// <% if (total_pages > 0) { %>

//     <% if (current_page > 1) { %>
//     <a href='<%=url%>/<%=(parseInt(current_page) - 1)%>' class="Normal">&larr;</a>
//     <% } %>

//     <% if (current_page >= max) { %>
//     <a href='<%=url%>/1' class="Normal">1</a>
//     ...
//     <% } %>

// </div>
// <% } %>

const languages = [
  {
    lang: "Hindi",
    langCode: "hi",
    country: "India",
    countryCode: "IN",
  },
  {
    lang: "Bengali",
    langCode: "bn",
    country: "Bangladesh",
    countryCode: "BD",
  },
  {
    lang: "Spanish",
    langCode: "es",
    country: "Spain",
    countryCode: "ES",
  },
  {
    lang: "English",
    langCode: "en",
    country: "United Kingdom",
    countryCode: "GB",
  },
  {
    lang: "Portuguese",
    langCode: "pt",
    country: "Portugal",
    countryCode: "PT",
  },
  {
    lang: "Russian",
    langCode: "ru",
    country: "Russia",
    countryCode: "RU",
  },
  {
    lang: "Japanese",
    langCode: "ja",
    country: "Japan",
    countryCode: "JP",
  },
  {
    lang: "Western Punjabi",
    langCode: "pnb",
    country: "Pakistan",
    countryCode: "PK",
  },
  {
    lang: "Marathi",
    langCode: "mr",
    country: "India",
    countryCode: "IN",
  },
  {
    lang: "Telugu",
    langCode: "te",
    country: "India",
    countryCode: "IN",
  },

  {
    lang: "Turkish",
    langCode: "tr",
    country: "Turkey",
    countryCode: "TR",
  },
  {
    lang: "Korean",
    langCode: "ko",
    country: "South Korea",
    countryCode: "KR",
  },
  {
    lang: "French",
    langCode: "fr",
    country: "France",
    countryCode: "FR",
  },
  {
    lang: "German",
    langCode: "de",
    country: "Germany",
    countryCode: "DE",
  },
  {
    lang: "Vietnamese",
    langCode: "vi",
    country: "Vietnam",
    countryCode: "VN",
  },
  {
    lang: "Tamil",
    langCode: "ta",
    country: "India",
    countryCode: "IN",
  },
  {
    lang: "Mandarin Chinese",
    langCode: "cmn",
    country: "China",
    countryCode: "CN",
  },
  {
    lang: "Wu Chinese",
    langCode: "wuu",
    country: "China",
    countryCode: "CN",
  },
  {
    lang: "Yue Chinese (Cantonese)",
    langCode: "yue",
    country: "China",
    countryCode: "CN",
  },
  {
    lang: "Urdu",
    langCode: "ur",
    country: "Pakistan",
    countryCode: "PK",
  },
  {
    lang: "Javanese",
    langCode: "jv",
    country: "Indonesia",
    countryCode: "ID",
  },
  {
    lang: "Italian",
    langCode: "it",
    country: "Italy",
    countryCode: "IT",
  },
  {
    lang: "Egyptian Arabic",
    langCode: "arz",
    country: "Egypt",
    countryCode: "EG",
  },
  {
    lang: "Gujarati",
    langCode: "gu",
    country: "India",
    countryCode: "IN",
  },
  {
    lang: "Iranian Persian",
    langCode: "fa",
    country: "Iran",
    countryCode: "IR",
  },
  {
    lang: "Bhojpuri",
    langCode: "bho",
    country: "India",
    countryCode: "IN",
  },
  {
    lang: "Southern Min",
    langCode: "nan",
    country: "China",
    countryCode: "CN",
  },
  {
    lang: "Hakka",
    langCode: "hak",
    country: "China",
    countryCode: "CN",
  },
  {
    lang: "Jin Chinese",
    langCode: "cjy",
    country: "China",
    countryCode: "CN",
  },
  {
    lang: "Hausa",
    langCode: "ha",
    country: "Nigeria",
    countryCode: "NG",
  },
  {
    lang: "Kannada",
    langCode: "kn",
    country: "India",
    countryCode: "IN",
  },
  {
    lang: "Indonesian",
    langCode: "id",
    country: "Indonesia",
    countryCode: "ID",
  },
  {
    lang: "Polish",
    langCode: "pl",
    country: "Poland",
    countryCode: "PL",
  },
  {
    lang: "Yoruba",
    langCode: "yo",
    country: "Nigeria",
    countryCode: "NG",
  },
  {
    lang: "Xiang Chinese",
    langCode: "hsn",
    country: "China",
    countryCode: "CN",
  },
  {
    lang: "Malayalam",
    langCode: "ml",
    country: "India",
    countryCode: "IN",
  },
  {
    lang: "Odia (Oriya)",
    langCode: "or",
    country: "India",
    countryCode: "IN",
  },
  {
    lang: "Maithili",
    langCode: "mai",
    country: "India",
    countryCode: "IN",
  },
  {
    lang: "Burmese",
    langCode: "my",
    country: "Myanmar",
    countryCode: "MM",
  },
  {
    lang: "Eastern Punjabi",
    langCode: "pa",
    country: "India",
    countryCode: "IN",
  },
  {
    lang: "Sunda",
    langCode: "su",
    country: "Indonesia",
    countryCode: "ID",
  },
  {
    lang: "Sudanese Arabic",
    langCode: "apd",
    country: "Sudan",
    countryCode: "SD",
  },
  {
    lang: "Algerian Arabic",
    langCode: "arq",
    country: "Algeria",
    countryCode: "DZ",
  },
  {
    lang: "Moroccan Arabic",
    langCode: "ary",
    country: "Morocco",
    countryCode: "MA",
  },
  {
    lang: "Ukrainian",
    langCode: "uk",
    country: "Ukraine",
    countryCode: "UA",
  },
  {
    lang: "Igbo",
    langCode: "ig",
    country: "Nigeria",
    countryCode: "NG",
  },
  {
    lang: "Northern Uzbek",
    langCode: "uz",
    country: "Uzbekistan",
    countryCode: "UZ",
  },
  {
    lang: "Sindhi",
    langCode: "sd",
    country: "Pakistan",
    countryCode: "PK",
  },
  {
    lang: "North Levantine Arabic",
    langCode: "apc",
    country: "Syria",
    countryCode: "SY",
  },
  {
    lang: "Romanian",
    langCode: "ro",
    country: "Romania",
    countryCode: "RO",
  },
  {
    lang: "Other",
    langCode: "69",
    country: "World",
    countryCode: "69",
  },
];

export default languages;

/*global iwin */
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import axios from "../../axios";
import AppDictionary from "../../translation";
import {
  formatNumber,
  getAvatarById,
  getDomain,
  getPathById,
  isLevelCompleted,
  settings,
  staticServerURL,
  transliterateString,
} from "../../utils";
import { AppContext } from "../../context";

// function setFocusToIframe() {
//   if (!isIOS()) {
//     $("#iframe_content").focus();
//     if (
//       $("#iframe_content").get(0) &&
//       $("#iframe_content").get(0).contentWindow &&
//       $("#iframe_content").get(0).contentWindow.focus &&
//       typeof $("#iframe_content").get(0).contentWindow.focus == "function"
//     )
//       $("#iframe_content").get(0).contentWindow.focus();
//   }
// }

const Game = ({ type }) => {
  const [appState, appDispatch] = React.useContext(AppContext);
  const history = useHistory();
  const { game } = useParams();

  console.log(appState.user);

  return (
    <iframe
      src={
        process.env.NODE_ENV === "development"
          ? `http://127.0.0.1:8082/${game}.html`
          : `${getDomain()}/${game}.html`
      }
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        zIndex: 1000,
        width: "100%",
        height: "100%",
      }}
      id="iframe_content"
      name="iframe_content"
      title={game}
    ></iframe>
  );
};

export default Game;

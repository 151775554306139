import React, { Component } from "react";
import { emptyPNG } from "../../utils";

const _loaded = {};
class ImageLoader extends Component {
  static defaultProps = {
    className: "",
    loadingClassName: "loading",
    loadedClassName: "loaded",
  };

  constructor(props, context) {
    super(props, context);
    this.state = { loaded: _loaded[props.src] };
  }

  onLoad = () => {
    console.log("LOADED");
    _loaded[this.props.src] = true;
    this.setState(() => ({ loaded: true }));
  };

  render() {
    let { className, loadedClassName, loadingClassName, ...props } = this.props;
    className = `${className} ${
      this.state.loaded ? loadedClassName : loadingClassName
    }`;

    return (
      <div
        style={{
          border: "1px solid #000",
          borderRadius: "5px",
          marginBottom: "6px",
        }}
      >
        {this.state.loaded ? (
          <img {...props} className={`animateOpacity ${className}`} />
        ) : (
          <>
            <img src={emptyPNG} className={className} />
            <img
              {...props}
              className={className}
              style={{ opacity: 0, display: "none" }}
              onLoad={this.onLoad}
            />
          </>
        )}
      </div>
    );
  }
}

export default ImageLoader;

/*global cordova */
import React, {
  useContext,
  useEffect,
  Suspense,
  lazy,
  useState,
  FC,
} from "react";

import "./App.scss";

import {
  BrowserRouter,
  HashRouter,
  Switch,
  Route,
  Link as RouterLink,
  useLocation,
  useHistory,
} from "react-router-dom";
import Header from "./parts/Header";
import Menu from "./parts/Menu";
import WelcomeScreen from "./app/WelcomeScreen";
import { AppContext } from "../context";
import usePageViews from "../hooks/usePageView";
import Catalog from "./app/Catalog";
import Game from "./app/Game";
import AppDictionary from "../translation";
import { defaultVocabulary, passDataToIframe, settings } from "../utils";
import Login from "./app/Login";
import SignIn from "./app/Login";
import HowTo from "./app/HowTo";
import IELTSVocabularies from "./app/IELTSVocabularies";
import IELTSVocabulary from "./app/IELTSVocabulary";
import Settings from "./app/Settings";
import GoogleTranslate from "./app/GoogleTranslate";
import Privacy from "./app/Privacy";
import YoutubeWritingCorrection from "./app/youtube-writing-correction";
const App = () => {
  usePageViews();
  const history = useHistory();
  const location = useLocation();
  const [appState, appDispatch] = useContext(AppContext);
  const [backLink, setBackLink] = React.useState();
  //const Router = !window.cordova ? BrowserRouter : HashRouter;
  const [isAppBannerVisible, setIsAppBannerVisible] = useState(true);

  const ua = navigator.userAgent.toLowerCase();
  const isAndroid = ua.indexOf("android") > -1;

  React.useEffect(() => {
    const eventListener = (event) => {
      const { type, data } = event.data;

      if (type === "settings-popup") {
        appDispatch({
          popup: "settings-popup",
        });
      } else if (type === "sync-vocabularies") {
        const { vocabularies } = appState;
        let vocabularyForIframe = defaultVocabulary;

        const vocabId = vocabularies.isCommonVocabulary ? 0 : data;
        const dataGame = localStorage.getItem(`_VING_GAME_${vocabId}_`);

        if (vocabularies.games[data].googleSheetId !== null && dataGame) {
          vocabularyForIframe = dataGame;
        }
        passDataToIframe("sync-vocabularies", vocabularyForIframe);
      } else if (type === "homepage") {
        history.push("/");
      } else {
        // console.log("Unhandled event ==>", event);
      }
    };

    window.addEventListener("message", eventListener, false);
    return () => {
      window.removeEventListener("message", eventListener);
    };
  }, [appState]);

  React.useEffect(() => {
    console.log("location ==> ", location);
    if (location.pathname === "/" || location.pathname === "/index.html") {
      setBackLink();
    } else if (location.pathname.includes("/ielts-vocabulary")) {
      setBackLink("/ielts-vocabularies");
    } else if (
      location.pathname.includes("/ielts-vocabularies") ||
      location.pathname.includes("/how-to") ||
      location.pathname.includes("/settings")
    ) {
      setBackLink("/");
    } else {
      setBackLink("history");
    }
  }, [location]);

  console.log("backLink ==> ", backLink);

  return (
    <div className={appState.isDrawerOpen ? "App show_left_nav" : "App"}>
      <link
        rel="stylesheet"
        href="//fonts.googleapis.com/css?family=Walter+Turncoat&amp;subset=latin"
      />
      <link
        rel="stylesheet"
        href="//fonts.googleapis.com/css?family=Neucha&amp;subset=latin"
      />
      {appState.popup === "video-writing-correction" && (
        <YoutubeWritingCorrection />
      )}
      {appState.popup === "sign-in" && <SignIn />}
      {appState.popup === "settings-popup" && <Settings isPopup={true} />}
      <Menu />
      <Header />

      <div id="content" className="content">
        {backLink && (
          <div
            className="backButton"
            onClick={() => {
              if (backLink === "history") {
                history.goBack();
              } else {
                history.push(backLink);
              }
            }}
          >
            <svg fill="#000000" viewBox="0 0 800 800">
              <path
                style={{
                  stroke: "#000000",
                  strokeWidth: 75,
                  strokeMiterlimit: 10,
                }}
                d="M295.6,395.2L541.9,121c10.4-11.5,10.4-30.2,0-41.7c-10.4-11.5-27.1-11.5-37.5,0l-265,295
              c-5,5.5-7.8,13-7.8,20.9c0,7.8,2.8,15.3,7.8,20.9l265,295c5.2,5.8,12,8.6,18.7,8.6s13.6-2.9,18.7-8.6c10.4-11.5,10.4-30.2,0-41.7
              L295.6,395.2z"
              />
            </svg>
            <span>Back</span>
          </div>
        )}
        {/* <div className="headerScore">
          <svg viewBox="0 0 47.94 47.94" width="25" height="25">
            <path
              d="M26.285,2.486l5.407,10.956c0.376,0.762,1.103,1.29,1.944,1.412l12.091,1.757
	c2.118,0.308,2.963,2.91,1.431,4.403l-8.749,8.528c-0.608,0.593-0.886,1.448-0.742,2.285l2.065,12.042
	c0.362,2.109-1.852,3.717-3.746,2.722l-10.814-5.685c-0.752-0.395-1.651-0.395-2.403,0l-10.814,5.685
	c-1.894,0.996-4.108-0.613-3.746-2.722l2.065-12.042c0.144-0.837-0.134-1.692-0.742-2.285l-8.749-8.528
	c-1.532-1.494-0.687-4.096,1.431-4.403l12.091-1.757c0.841-0.122,1.568-0.65,1.944-1.412l5.407-10.956
	C22.602,0.567,25.338,0.567,26.285,2.486z"
            />
          </svg>{" "}
          <span>23</span>
        </div> */}

        <style>{`
  .container1{
    padding-top: 10px;
    display: flex;
    color: #fff;
    max-width: 600px;
    margin: 0 auto;
    justify-content: center;

  }
  .button{

  }
  .logo img{
    border-radius: 5px;
    margin: 4px 16px 0 15px;
  }
  .banner{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99999999999;
    background-color: #012c57;
  }
  .container2 {
    display: flex;  
    height: 90px;
    align-items: center;
    justify-content: center;
  }
  .corAppButton{
    background-color: #bd2430;
    color: #fff;
    font-size: 20px;
    padding: 10px 23px 7px;
    border-radius: 5px;
  }
  .corHeader{
    font-size: 24px;
    line-height: 24px;
    font-weight: bold;
    margin-top: 16px;
    margin-bottom: 6px;
    letter-spacing: 1px;
  }
  .corText{
    padding-right: 10px;
    padding-right: 10px;
    line-height: 20px;
    font-size: 20px;
    letter-spacing: 1px;
  }
  .closeIcon{
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
  }
  .corAppText {
    text-align: left;
  }

`}</style>
        {isAppBannerVisible &&
          isAndroid &&
          !window.cordova &&
          window.screen.width < 850 && (
            <div className="banner">
              <div className="container1">
                <div className="logo">
                  <img src="/static/app-logo.png" width="70" />
                </div>
                <div className="corAppText">
                  <div className="corHeader">Start Vocabularying!</div>
                  <div className="corText">
                    Learn new words, while having fun.
                  </div>
                </div>

                <svg
                  className="closeIcon"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="times"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 352 512"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsAppBannerVisible(false);
                  }}
                >
                  <path
                    fill="currentColor"
                    d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                  />
                </svg>
              </div>
              <div className="container2">
                <div
                  className="corAppButton"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsAppBannerVisible(false);
                    window.location.href =
                      "https://play.google.com/store/apps/details?id=com.vocabularying";
                  }}
                >
                  Download App for Free
                </div>
              </div>
            </div>
          )}
        {/**
           * 
12 > https://essaycheck.ieltspodcast.co/one-month      Thank you for your subscription of the essay checker! Click here to get started. 
$27> https://essaycheck.ieltspodcast.co/three-month      Thank you for your subscription of the essay checker! Click here to get started. 
$36 > https://essaycheck.ieltspodcast.co/six-month      Thank you for your subscription of the essay checker! Click here to get started. 

           */}

        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route exact path="/">
              <WelcomeScreen />
            </Route>
            <Route exact path="/index.html">
              <WelcomeScreen />
            </Route>
            <Route exact path="/how-to">
              <HowTo />
            </Route>
            <Route exact path="/ielts-vocabularies">
              <IELTSVocabularies />
            </Route>
            <Route exact path="/google-translate">
              <GoogleTranslate />
            </Route>

            <Route exact path="/ielts-vocabulary/:band">
              <IELTSVocabulary />
            </Route>
            <Route exact path="/ielts-vocabulary/:band/:page">
              <IELTSVocabulary />
            </Route>
            <Route exact path="/career">
              <Catalog type="selectLevel" />
            </Route>
            <Route exact path="/career/:page">
              <Catalog type="career" />
            </Route>
            <Route exact path="/game/:game">
              <Game />
            </Route>
            <Route exact path="/sign-in">
              <Login />
            </Route>

            <Route exact path="/settings">
              <Settings />
            </Route>

            <Route exact path="/privacy">
              <Privacy />
            </Route>

            <Route path="/">
              <div>Not Found</div>
            </Route>
          </Switch>
        </Suspense>
        <div
          className="overlayZ"
          onClick={() => {
            appDispatch({ isDrawerOpen: false });
          }}
        ></div>
      </div>
    </div>
  );
};

export default App;

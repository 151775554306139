import { emptyPNG, getPathById, staticServerURL } from "../../utils";
import ImageLoader from "./ImageLoader";
const TrackThumb = ({ trackId }) => {
  return (
    <ImageLoader
      src={
        trackId === 1234567
          ? emptyPNG
          : `${staticServerURL}/tracks/${getPathById(trackId)}.png`
      }
      className="trackThumbImage"
    />
  );
};

export default TrackThumb;

/*global ga*/
import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { AppContext } from "../context";

function usePageViews() {
  let location = useLocation();
  const [appState, appDispatch] = useContext(AppContext);
  React.useEffect(() => {
    // ga.send(["pageview", location.pathname]);
    // console.log("pageview", location.pathname);
    // if (window.gtag) {
    //   window.gtag("config", "G-WM7ZPKWMXT", {
    //     page_title: location.pathname,
    //     page_path: location.pathname,
    //   });
    // }
    appDispatch({ isDrawerOpen: false });

    document.getElementById("root").scrollTo({
      top: 0,
      behavior: "smooth",
    });
    // if (ga) {
    //   ga("send", "pageview", `/app${location.pathname}`);
    // }
  }, [location]);
}

export default usePageViews;

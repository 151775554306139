import { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import React from "react";
import { AppContext } from "../../context";

const GoogleTranslate = () => {
  return (
    <div className="welcomeScreen">
      <div className="landingScreen">
        <h1>How To Export Google Translate History to Google Sheets</h1>
        <h2>
          1). Go to{" "}
          <a
            href="https://translate.google.com/"
            target="_blank"
            rel="noreferrer"
          >
            Google Translate
          </a>{" "}
          and Export Translate History:{" "}
        </h2>
        <img
          src="/static/googletranslate.png"
          style={{
            width: "100%",
            border: "1px solid #000",
            borderRadius: "15px",
            maxWidth: "600px",
          }}
        />
      </div>
    </div>
  );
};
export default GoogleTranslate;

import React, { useState, useContext, useRef, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { AppContext, getUserVocabularies } from "../../context";
import { useHistory } from "react-router-dom";
import axios from "../../axios";
import "./login.scss";
import {
  getCookie,
  setJWT,
  getDomain,
  passDataToIframe,
  openExternalLink,
  removeJWT,
  defaultSheetId,
  removeVocabularies,
} from "../../utils";
import { encode, convertUTF16 } from "../../utils";
import languages from "../../utilsLangs";

const Settings = ({ isPopup = false }) => {
  const reCaptchaRef = useRef(null); // React.createRef<ReCAPTCHA>();
  const [appState, appDispatch] = useContext(AppContext);
  const [activeGameId, setActiveGameId] = useState(null);
  const [inputURL, setInputURL] = useState("");
  const [callback, setCallback] = useState(null);
  const [errorMessages, setErrorMessages] = useState(null);

  const [saving, setSaving] = useState(false);

  const [languageNative, setLanguageNative] = useState(
    appState.vocabularies.languageNative || ""
  );
  const [languageLearn, setLanguageLearn] = useState(
    appState.vocabularies.languageLearn || "en"
  );

  const showErrors = () => {
    let errors;

    if (Array.isArray(errorMessages)) {
      errors = errorMessages.map((error) => {
        if (typeof error === "string") {
          return (
            <li className="error" key={error}>
              {error}
            </li>
          );
        }
        if (error.message) {
          return (
            <li className="error" key={error.message}>
              {error.message}
            </li>
          );
        }
        return null;
      });
    } else {
      errors = <li className="error">{errorMessages}</li>;
    }
    return <ul className="messageList">{errors}</ul>;
  };

  useEffect(() => {
    if (
      appState.vocabularies.languageNative ||
      appState.vocabularies.languageLearn
    )
      return;

    // Detect the browser's preferred language
    const browserLang = navigator.language.split("-")[0];

    // Find the language in your list that matches the browser language
    const matchingLang = languages.find((lang) =>
      lang.langCode.includes(browserLang)
    );

    // Set the detected language as selected, if found
    if (matchingLang) {
      setLanguageNative(matchingLang.langCode);
    }

    if (matchingLang && matchingLang.langCode !== "en") {
      // do nothing
    } else {
      fetch("https://www.cloudflare.com/cdn-cgi/trace")
        .then((response) => response.text())
        .then(async (data) => {
          try {
            let country = "XX";
            const parts = data.split("loc=");
            if (parts.length > 1) {
              const parts2 = parts[1].split("\n");
              if (parts2.length > 0) {
                country = parts2[0];
              }
            }

            const matchingLang = languages.find((lang) =>
              lang.countryCode.includes(country)
            );

            // Set the detected language as selected, if found
            if (matchingLang) {
              setLanguageNative(matchingLang.langCode);
            }
          } catch (e) {
            console.log("Cloudflare / Country / Error ", e);
          }
        })
        .catch((error) => {
          console.log("Cloudflare / Country / Error ", error);
        });
    }
  }, []);

  const modifiedAt = (gameId) => {
    console.log(
      "games[gameId].locallyModified ",
      games[gameId].locallyModified
    );
    console.log("games[gameId].googleModified", games[gameId].googleModified);
    return games[gameId].locallyModified &&
      new Date(games[gameId].locallyModified).getTime() >=
        new Date(games[gameId].googleModified).getTime() ? (
      new Date(games[gameId].locallyModified).toLocaleString()
    ) : (
      <b className="needUpdate">Need update</b>
    );
  };

  const updateButton = (gameId) => {
    return (
      <div
        onClick={() => {
          openGoogleSheetInput(gameId);
        }}
        className="changeButton"
      >
        {games[gameId].locallyModified &&
        new Date(games[gameId].locallyModified).getTime() >=
          new Date(games[gameId].googleModified).getTime()
          ? "Change"
          : "Update"}
      </div>
    );
  };

  const openExternalSheet = (sheetId) => {
    openExternalLink(`https://docs.google.com/spreadsheets/d/${sheetId}/edit`);
  };

  const resetCaptcha = () => {
    if (reCaptchaRef && reCaptchaRef.current) {
      reCaptchaRef.current.reset();
    }
  };

  const onCaptchaEntered = (value) => {
    if (value && callback) callback();
    // window.ga("send", "event", "Process_Text", "Ok", "Ok");
  };

  const downloadVocabulary = (e) => {
    e.preventDefault();
    const match = inputURL.match(/spreadsheets\/d\/.*\/edit/gm);
    if (match) {
      const spreadsheetId = match[0]
        .replace("spreadsheets/d/", "")
        .replace("/edit", "");

      if (spreadsheetId === defaultSheetId) {
        setActiveGameId(null);
        return;
      }

      setCallback(() => async () => {
        setErrorMessages([]);

        try {
          const response = await axios.post("/api/tests/download-vocabulary", {
            gameId2Update: activeGameId,
            sheetId2Update: spreadsheetId,
            reCaptcha: reCaptchaRef.current?.getValue(),
          });

          const { gameId, googleSheetId, name, googleModified, VOC } =
            response.data;

          const vocabularies = JSON.parse(
            JSON.stringify(appState.vocabularies)
          );

          vocabularies.games[gameId] = {
            name,
            googleSheetId,
            locallyModified: googleModified,
            googleModified,
          };

          appDispatch({
            vocabularies,
          });

          localStorage.setItem(`_VING_GAME_${gameId}_`, VOC);
          setActiveGameId(null);

          // const { VOC, title, lastModified } = response.data;
          // if (JWT) {
          //   processJWT(JWT);
          // }
        } catch (err) {
          setErrorMessages(err.response.data.message);
        } finally {
          setCallback(null);
          resetCaptcha();
        }
      });
    } else {
      setErrorMessages("Google Sheet URL is not valid.");
    }
  };

  const saveUserLanguages = async () => {
    setSaving(true);
    await axios.post("/api/tests/user-languages", {
      languageNative,
      languageLearn,
    });
    await getUserVocabularies(appState, appDispatch);
    setSaving(false);
  };

  const { games, isCommonVocabulary } = appState.vocabularies;

  const openGoogleSheetInput = (gameId) => {
    if (appState.user) {
      setActiveGameId(gameId);
      setInputURL(
        `https://docs.google.com/spreadsheets/d/${games[gameId].googleSheetId}/edit`
      );
    } else {
      appDispatch({
        popup: "sign-in",
      });
    }
  };

  const isLangNotSet =
    !appState.vocabularies.languageNative ||
    !appState.vocabularies.languageLearn;

  const content = (
    <div className="welcomeScreen settingsPopup">
      <style>{`

.messageList .error{
  font-size: 21px;
  color: red;
  text-align: center;
  padding-bottom: 11px;
}


      .settingsPopup{
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
      }
            .sheetName {
                font-family: Arial;
                border-bottom: 2px dotted #2aa363;
                letter-spacing: 0;
                
            }
            .sheetFile {
               cursor: pointer;
               margin-bottom: 4px;
            }
            .sheetFile svg.sheetLogo {
                width: 20px;
                margin-bottom: -7px;
            }
            .sheetFile svg.sheetLogo {
                width: 20px;
                margin-bottom: -7px;
            }
            .sheetFile svg.outgoingLink {

            }

            .tableHeader{
                font-weight: bold;
                padding: 3px 21px;
                font-size: 22px;
            }
            .vocabulariesTable {
                letter-spacing: 1px;
                margin: 0 auto;
            }
            .vocabulariesTable td{ 
                padding: 3px 19px;
                padding-bottom: 17px;     
                vertical-align: middle;           
            }
            .gameIcon {
                width: 50px;
                border: 1px solid #000;
                border-radius: 5px;
                margin-right: 11px;            
            }
            .changeButton {
                background-color: #34a568;
                color: #fff;
                /* height: 30px; */
                border-radius: 4px;
                padding: 5px 11px;
                display: inline-block;
                text-decoration: none;
                cursor: pointer;
            }
                          
            .changeButton:hover {
                text-decoration: none;
              }

            .vocabulariesTable{
                border-spacing: 2px;
                border-collapse: separate;
                width: 100%;
            }

            .vocabulariesTable td{ 
                padding: 7px 12px;
                background-color: #eee;
            }
            .vocabulariesTable .tableHeader td{
                background-color: #5d5d5d;
                color: #fff;
            }

            .gameContainer{
                text-align: left;
                display: flex;
                vertical-align: middle;
                align-items: center;
                justify-content: flex-start;
            }
            .sameSheetForAllGames {
              font-size: 23px;
              display: inline-block;
              background-color: #eee;
              padding: 10px 12px;
              border-radius: 5px;
              margin-top: 29px;
              letter-spacing: 1px;
              /* border: 1px solid #dfdcdc; */
              cursor: pointer;
            }
            .sameSheetForAllGames svg {
                margin-bottom: -7px;
            }

            .inputURL{
                font-size: 17px;
                margin: 11px auto;
                width: 100%;
                box-sizing: border-box;
                padding: 5px;
            }
            .tableForInputURL{
                padding: 0 15px;
            }
            .tableBody td{
                padding: 20px;
                text-align: left;

            }

            .actionButtons {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
            }
            .actionButtons .changeButton{
                font-size: 23px;
            }

            .changeButton.greyBtn {
                background-color: #dbdada;
                color: #000;
                border: 1px solid #000;
            }

            .overlay .closeBtn{
                position: absolute;
                top: 13px;
                right: 20px;
                width: 22px;
                display: block !important;
                cursor: pointer;
            }
            .settingsPopup .landingScreen{
                background-color: #fff;
                border-radius: 10px;
                padding-bottom: 25px;
            }


            .vocabulariesTable .captchaOverlay {
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.8);
                position: fixed;
                top: 0;
                left: 0;
                z-index: 1000;
            }

            .vocabulariesTable .captchaWrapper {
                position: relative;
                z-index: 161;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 1001;
              }

             .overlay .vocabulariesTable .captchaOverlay {
                position: absolute !important;
                border-radius: 9px;
              }

              .vocabulariesTable .needUpdate{
                color: red;
                font-size: 20px;
              }

              @media (max-width: 500px) {
                .vocabulariesTable td:nth-child(3) {
                  display: none;
                }
                .gameName{
                  display: none;
                }
                .gameContainer {
                  justify-content: center;
                }
                .gameIcon {
                  margin-right: 0;
                }
              }
              
        `}</style>

      <div
        className="landingScreen"
        style={{
          position: "relative",
        }}
      >
        <svg
          aria-hidden="true"
          focusable="false"
          viewBox="0 0 352 512"
          className="closeBtn"
          style={{ display: "none" }}
          onClick={() => {
            passDataToIframe("reload-game");
            appDispatch({
              popup: null,
            });
          }}
        >
          <path
            fill="currentColor"
            d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
          ></path>
        </svg>
        <h1>
          {/* IELTS <b>Vocabularies</b> */}
          Settings
        </h1>

        {activeGameId === null && (
          <>
            <table border-spacing="1" className="vocabulariesTable">
              <tr className="tableHeader">
                <td>Game</td>
                <td>Google Sheet</td>
                <td>Updated At</td>
                <td>Action</td>
              </tr>

              {isCommonVocabulary ? (
                <>
                  <tr>
                    <td>
                      <div className="gameContainer">
                        <img
                          src="/static/candy-logo.jpg"
                          class="gameIcon"
                          alt="candy"
                        />
                        <span className="gameName">Word Candy Match</span>
                      </div>
                    </td>
                    <td rowSpan={2}>
                      <div
                        className="sheetFile"
                        onClick={() => {
                          openExternalSheet(games[0].googleSheetId);
                        }}
                      >
                        {gSheetIcon}
                        <span className="sheetName">{games[0].name}&nbsp;</span>
                        {externalLink}
                      </div>
                    </td>
                    <td rowSpan={2}>{modifiedAt(0)}</td>
                    <td rowSpan={2}>{updateButton(0)}</td>
                  </tr>
                  <tr>
                    <td className="gameInfo">
                      <div className="gameContainer">
                        <img
                          src="/static/space-logo2.jpg"
                          class="gameIcon"
                          alt="space"
                        />
                        <span className="gameName">Space Words</span>
                      </div>
                    </td>
                  </tr>
                </>
              ) : (
                <>
                  <tr>
                    <td>
                      <div className="gameContainer">
                        <img
                          src="/static/candy-logo.jpg"
                          class="gameIcon"
                          alt="candy"
                        />

                        <span className="gameName">Word Candy Match</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="sheetFile"
                        onClick={() => {
                          openExternalSheet(games[1].googleSheetId);
                        }}
                      >
                        {gSheetIcon}
                        <span className="sheetName">{games[1].name}&nbsp;</span>
                        {externalLink}
                      </div>
                    </td>
                    <td>{modifiedAt(1)}</td>
                    <td>{updateButton(1)}</td>
                  </tr>
                  <tr>
                    <td className="gameInfo">
                      <div className="gameContainer">
                        <img
                          src="/static/space-logo2.jpg"
                          class="gameIcon"
                          alt="space"
                        />
                        <span className="gameName">Space Words</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="sheetFile"
                        onClick={() => {
                          openExternalSheet(games[2].googleSheetId);
                        }}
                      >
                        {gSheetIcon}
                        <span className="sheetName">{games[2].name}&nbsp;</span>
                        {externalLink}
                      </div>
                    </td>
                    <td>{modifiedAt(2)}</td>
                    <td>{updateButton(2)}</td>
                  </tr>
                </>
              )}
            </table>
            <div
              className="sameSheetForAllGames"
              onClick={(e) => {
                e.preventDefault();

                const newValue = !isCommonVocabulary;
                appDispatch({
                  vocabularies: {
                    ...appState.vocabularies,
                    isCommonVocabulary: newValue,
                  },
                });

                axios.post("/api/tests/common-vocabulary", {
                  isCommonVocabulary: newValue ? 1 : 0,
                });
              }}
            >
              {isCommonVocabulary ? (
                <svg fill="#000000" width="30px" viewBox="0 0 512 512">
                  <path d="M400,48H112a64.07,64.07,0,0,0-64,64V400a64.07,64.07,0,0,0,64,64H400a64.07,64.07,0,0,0,64-64V112A64.07,64.07,0,0,0,400,48ZM364.25,186.29l-134.4,160a16,16,0,0,1-12,5.71h-.27a16,16,0,0,1-11.89-5.3l-57.6-64a16,16,0,1,1,23.78-21.4l45.29,50.32L339.75,165.71a16,16,0,0,1,24.5,20.58Z" />
                </svg>
              ) : (
                <svg width="30px" viewBox="0 0 21 21">
                  <path
                    d="m2.5.5h10c1.1045695 0 2 .8954305 2 2v10c0 1.1045695-.8954305 2-2 2h-10c-1.1045695 0-2-.8954305-2-2v-10c0-1.1045695.8954305-2 2-2z"
                    fill="none"
                    stroke="#000000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    transform="translate(3 3)"
                  />
                </svg>
              )}{" "}
              Use one Google Sheet for all Games
            </div>
          </>
        )}

        {activeGameId !== null && activeGameId >= 0 && (
          <>
            <table
              border-spacing="1"
              className="vocabulariesTable tableForInputURL"
            >
              <tr className="tableHeader">
                <style>{`
                  .tableHeader b {
                    background-color: #000;
                    padding: 4px 9px 2px;
                    border-radius: 6px;
                  }
                `}</style>
                <td>
                  Vocabulary
                  {` `}for{` `}
                  <b>
                    {activeGameId === 0 && "All Games"}
                    {activeGameId === 1 && "Word Candy Match"}
                    {activeGameId === 2 && "Space Words"}
                  </b>
                </td>
              </tr>
              <tr className="tableBody">
                <td>
                  {showErrors()}
                  <div
                    style={{
                      fontSize: "21px",
                    }}
                  >
                    Public Google Sheet URL:
                  </div>
                  <div>
                    <input
                      type="text"
                      className="inputURL"
                      value={inputURL}
                      onChange={(e) => {
                        if (errorMessages) {
                          setErrorMessages();
                        }
                        setInputURL(e.target.value);
                      }}
                    />
                  </div>
                  <div className="actionButtons">
                    <div
                      onClick={() => {
                        setActiveGameId(null);
                        setErrorMessages();
                      }}
                      className="changeButton greyBtn"
                    >
                      Cancel
                    </div>
                    <div
                      style={{
                        display: callback ? "block" : "none",
                      }}
                    >
                      <div className="captchaWrapper">
                        <ReCAPTCHA
                          ref={reCaptchaRef}
                          onChange={onCaptchaEntered}
                          sitekey="6Lepq-kZAAAAAGKf0lT5KHuUS3sQDYnuc77NZijB"
                        />
                      </div>
                      <div
                        onClick={() => {
                          setCallback(null);
                        }}
                        className="captchaOverlay"
                        aria-hidden="true"
                      />
                    </div>
                    {!callback && (
                      <div
                        className="changeButton"
                        onClick={downloadVocabulary}
                      >
                        Load
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            </table>
          </>
        )}

        {appState.user && (
          <div
            className={
              isLangNotSet ? "tellAbout" : "tellAbout tellAboutWithOpacity"
            }
          >
            <style>{`

.overlay .tellAbout {
  display: none !important;
}

            .tellAboutWithOpacity {
              opacity: 0.2;
              transition: opacity 100ms ease-in-out;
            }
            .tellAboutWithOpacity:hover {
              opacity: 1;
            }

          .tellAbout{
            margin-top: 30px;
          }
            .tellAbout select {
              font-size: 23px;
              padding: 4px 4px 2px;
              letter-spacing: 1px;
              font-family: 'Neucha';
            }
            .tellAbout .langTitle{
              font-size: 23px;
              font-family: 'Neucha';
              letter-spacing: 1px;
              text-align: left;
              padding-bottom: 5px;
            }
            .tellAboutContainer {
              display: flex;
              flex-direction: row;
              justify-content: space-around;
              align-items: flex-end;
              padding: 29px;
              border: 1px solid #000;
              border-radius: 10px;
          }
          .saveButton{
            font-size: 30px;
            padding: 10px 20px 6px;
            height: 30px;
          }

          @media (max-width: 775px) {
            .tellAboutContainer {
              flex-direction: column;
              align-items: center;
            }
            .tellAboutContainer .sideB {
              margin-top: 30px;
              margin-bottom: 30px;
            }
          }

          .vocabularyingApp  .deleteMyAccount {
            display: inline-block !important;
  font-size: 23px;
    display: inline-block;
    padding: 10px 15px;
    border: 1px solid #000;
    border-radius: 14px;
    margin-top: 30px;
}
.vocabularyingApp  .deleteMyAccount svg{
  width: 33px;
  margin-bottom: -7px;
  margin-top: -6px;
  margin-right: 5px;
}

          `}</style>
            {isLangNotSet && <h2>Tell us about yourself</h2>}
            <div className="tellAboutContainer">
              <div className="sideA">
                <div className="langTitle">Your Native Language </div>
                <select
                  value={languageNative}
                  onChange={(event) => {
                    setLanguageNative(event.target.value);
                  }}
                >
                  {languages.map((language, index) => (
                    <option key={index} value={language.langCode}>
                      {language.lang}
                    </option>
                  ))}
                </select>
              </div>
              <div className="sideB">
                <div className="langTitle">Language You Learn </div>
                <select
                  value={languageLearn}
                  onChange={(event) => {
                    setLanguageLearn(event.target.value);
                  }}
                >
                  {languages.map((language, index) => (
                    <option key={index} value={language.langCode}>
                      {language.lang}
                    </option>
                  ))}
                </select>
              </div>
              <div
                className={
                  isLangNotSet
                    ? "changeButton saveButton"
                    : "changeButton greyBtn"
                }
                onClick={saveUserLanguages}
              >
                {saving ? "Saving..." : isLangNotSet ? "Save" : "Update"}
              </div>
            </div>
            <div
              className="deleteMyAccount"
              style={{ display: "none" }}
              onClick={() => {
                appDispatch({
                  user: null,
                });
                removeJWT();
                removeVocabularies();
                window.location.reload();
              }}
            >
              <svg focusable="false" viewBox="0 0 24 24">
                <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zm2.46-7.12l1.41-1.41L12 12.59l2.12-2.12 1.41 1.41L13.41 14l2.12 2.12-1.41 1.41L12 15.41l-2.12 2.12-1.41-1.41L10.59 14l-2.13-2.12zM15.5 4l-1-1h-5l-1 1H5v2h14V4z"></path>
              </svg>
              Delete My Account
            </div>

            {window.cordova && (
              <div>
                <br />
                <br />
                <br />
                <br />

                <div
                  onClick={() => {
                    const appId = "com.vocabularying";
                    if (window.cordova) {
                      // eslint-disable-next-line
                      // LaunchReview.rating();
                      // localStorage.setItem("isAndroidReviewShowed", true);
                      // appDispatch({ popupGive5StarReview: false });
                      // eslint-disable-next-line
                      LaunchReview.launch(
                        function () {
                          localStorage.setItem("isAndroidReviewShowed", true);
                          appDispatch({ popupGive5StarReview: false });
                        },
                        function (err) {
                          alert(
                            "Error launching store app: " + JSON.stringify(err)
                          );
                        },
                        appId
                      );
                    }
                  }}
                >
                  REVIEW
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );

  if (isPopup) {
    return <div className="overlay">{content}</div>;
  } else {
    return content;
  }
};
const gSheetIcon = (
  <>
    {" "}
    <svg viewBox="0 0 49 67" version="1.1" className="sheetLogo">
      <defs>
        <path
          d="M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L29.5833333,0 Z"
          id="path-1"
        ></path>
        <path
          d="M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L29.5833333,0 Z"
          id="path-3"
        ></path>
        <path
          d="M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L29.5833333,0 Z"
          id="path-5"
        ></path>
        <linearGradient
          x1="50.0053945%"
          y1="8.58610612%"
          x2="50.0053945%"
          y2="100.013939%"
          id="linearGradient-7"
        >
          <stop stop-color="#263238" stop-opacity="0.2" offset="0%"></stop>
          <stop stop-color="#263238" stop-opacity="0.02" offset="100%"></stop>
        </linearGradient>
        <path
          d="M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L29.5833333,0 Z"
          id="path-8"
        ></path>
        <path
          d="M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L29.5833333,0 Z"
          id="path-10"
        ></path>
        <path
          d="M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L29.5833333,0 Z"
          id="path-12"
        ></path>
        <path
          d="M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L29.5833333,0 Z"
          id="path-14"
        ></path>
        <radialGradient
          cx="3.16804688%"
          cy="2.71744318%"
          fx="3.16804688%"
          fy="2.71744318%"
          r="161.248516%"
          gradientTransform="translate(0.031680,0.027174),scale(1.000000,0.727273),translate(-0.031680,-0.027174)"
          id="radialGradient-16"
        >
          <stop stop-color="#FFFFFF" stop-opacity="0.1" offset="0%"></stop>
          <stop stop-color="#FFFFFF" stop-opacity="0" offset="100%"></stop>
        </radialGradient>
      </defs>
      <g
        id="Page-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Consumer-Apps-Sheets-Large-VD-R8-"
          transform="translate(-451.000000, -451.000000)"
        >
          <g id="Hero" transform="translate(0.000000, 63.000000)">
            <g id="Personal" transform="translate(277.000000, 299.000000)">
              <g id="Sheets-icon" transform="translate(174.833333, 89.958333)">
                <g id="Group">
                  <g id="Clipped">
                    <mask id="mask-2" fill="white">
                      <use href="#path-1"></use>
                    </mask>
                    <g id="SVGID_1_"></g>
                    <path
                      d="M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L36.9791667,10.3541667 L29.5833333,0 Z"
                      id="Path"
                      fill="#0F9D58"
                      fill-rule="nonzero"
                      mask="url(#mask-2)"
                    ></path>
                  </g>
                  <g id="Clipped">
                    <mask id="mask-4" fill="white">
                      <use href="#path-3"></use>
                    </mask>
                    <g id="SVGID_1_"></g>
                    <path
                      d="M11.8333333,31.8020833 L11.8333333,53.25 L35.5,53.25 L35.5,31.8020833 L11.8333333,31.8020833 Z M22.1875,50.2916667 L14.7916667,50.2916667 L14.7916667,46.59375 L22.1875,46.59375 L22.1875,50.2916667 Z M22.1875,44.375 L14.7916667,44.375 L14.7916667,40.6770833 L22.1875,40.6770833 L22.1875,44.375 Z M22.1875,38.4583333 L14.7916667,38.4583333 L14.7916667,34.7604167 L22.1875,34.7604167 L22.1875,38.4583333 Z M32.5416667,50.2916667 L25.1458333,50.2916667 L25.1458333,46.59375 L32.5416667,46.59375 L32.5416667,50.2916667 Z M32.5416667,44.375 L25.1458333,44.375 L25.1458333,40.6770833 L32.5416667,40.6770833 L32.5416667,44.375 Z M32.5416667,38.4583333 L25.1458333,38.4583333 L25.1458333,34.7604167 L32.5416667,34.7604167 L32.5416667,38.4583333 Z"
                      id="Shape"
                      fill="#F1F1F1"
                      fill-rule="nonzero"
                      mask="url(#mask-4)"
                    ></path>
                  </g>
                  <g id="Clipped">
                    <mask id="mask-6" fill="white">
                      <use href="#path-5"></use>
                    </mask>
                    <g id="SVGID_1_"></g>
                    <polygon
                      id="Path"
                      fill="url(#linearGradient-7)"
                      fill-rule="nonzero"
                      mask="url(#mask-6)"
                      points="30.8813021 16.4520313 47.3333333 32.9003646 47.3333333 17.75"
                    ></polygon>
                  </g>
                  <g id="Clipped">
                    <mask id="mask-9" fill="white">
                      <use href="#path-8"></use>
                    </mask>
                    <g id="SVGID_1_"></g>
                    <g id="Group" mask="url(#mask-9)">
                      <g transform="translate(26.625000, -2.958333)">
                        <path
                          d="M2.95833333,2.95833333 L2.95833333,16.2708333 C2.95833333,18.7225521 4.94411458,20.7083333 7.39583333,20.7083333 L20.7083333,20.7083333 L2.95833333,2.95833333 Z"
                          id="Path"
                          fill="#87CEAC"
                          fill-rule="nonzero"
                        ></path>
                      </g>
                    </g>
                  </g>
                  <g id="Clipped">
                    <mask id="mask-11" fill="white">
                      <use href="#path-10"></use>
                    </mask>
                    <g id="SVGID_1_"></g>
                    <path
                      d="M4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,4.80729167 C0,2.36666667 1.996875,0.369791667 4.4375,0.369791667 L29.5833333,0.369791667 L29.5833333,0 L4.4375,0 Z"
                      id="Path"
                      fill-opacity="0.2"
                      fill="#FFFFFF"
                      fill-rule="nonzero"
                      mask="url(#mask-11)"
                    ></path>
                  </g>
                  <g id="Clipped">
                    <mask id="mask-13" fill="white">
                      <use href="#path-12"></use>
                    </mask>
                    <g id="SVGID_1_"></g>
                    <path
                      d="M42.8958333,64.7135417 L4.4375,64.7135417 C1.996875,64.7135417 0,62.7166667 0,60.2760417 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,60.2760417 C47.3333333,62.7166667 45.3364583,64.7135417 42.8958333,64.7135417 Z"
                      id="Path"
                      fill-opacity="0.2"
                      fill="#263238"
                      fill-rule="nonzero"
                      mask="url(#mask-13)"
                    ></path>
                  </g>
                  <g id="Clipped">
                    <mask id="mask-15" fill="white">
                      <use href="#path-14"></use>
                    </mask>
                    <g id="SVGID_1_"></g>
                    <path
                      d="M34.0208333,17.75 C31.5691146,17.75 29.5833333,15.7642188 29.5833333,13.3125 L29.5833333,13.6822917 C29.5833333,16.1340104 31.5691146,18.1197917 34.0208333,18.1197917 L47.3333333,18.1197917 L47.3333333,17.75 L34.0208333,17.75 Z"
                      id="Path"
                      fill-opacity="0.1"
                      fill="#263238"
                      fill-rule="nonzero"
                      mask="url(#mask-15)"
                    ></path>
                  </g>
                </g>
                <path
                  d="M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L29.5833333,0 Z"
                  id="Path"
                  fill="url(#radialGradient-16)"
                  fill-rule="nonzero"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
    &nbsp;
  </>
);
const externalLink = (
  <svg width="13px" className="outgoingLink" viewBox="0 0 32.822 32.822">
    <g id="Lager_80" data-name="Lager 80" transform="translate(0 0.822)">
      <path
        id="Path_89"
        data-name="Path 89"
        d="M24,22v5a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V9A1,1,0,0,1,5,8h5a2,2,0,0,0,2-2h0a2,2,0,0,0-2-2H3A3,3,0,0,0,0,7V29a3,3,0,0,0,3,3H25a3,3,0,0,0,3-3V22a2,2,0,0,0-2-2h0A2,2,0,0,0,24,22Z"
        fill="#161615"
      />
      <rect
        id="Rectangle_40"
        data-name="Rectangle 40"
        width="16"
        height="4"
        rx="2"
        transform="translate(16 0)"
        fill="#161615"
      />
      <rect
        id="Rectangle_41"
        data-name="Rectangle 41"
        width="16"
        height="4"
        rx="2"
        transform="translate(32 0) rotate(90)"
        fill="#161615"
      />
      <g id="Group_37" data-name="Group 37">
        <rect
          id="Rectangle_42"
          data-name="Rectangle 42"
          width="32.296"
          height="3.971"
          rx="1.986"
          transform="translate(7.178 22.014) rotate(-45)"
          fill="#161615"
        />
      </g>
    </g>
  </svg>
);

export default Settings;

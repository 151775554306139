/*global cordova */
import React from "react";
import Router from "./components/Router";
import ReactDOM from "react-dom";
import App from "./components/App";
import reportWebVitals from "./reportWebVitals";

declare global {
  interface Window {
    cordova: any;
  }
}
const startApp = () => {
  ReactDOM.render(<Router />, document.getElementById("root"));
};

if (!window.cordova) {
  startApp();
} else {
  document.addEventListener(
    "deviceready",
    () => {
      startApp();
    },
    false
  );
}

import React, { useRef, useContext, useState } from "react";
import axios from "../../axios";
import { AppContext } from "../../context";
import { timeSince } from "../../utils";
import ReCAPTCHA from "react-google-recaptcha";
import { goToLink } from "./utils";

// declare global {
//   interface Window {
//     ga: any;
//   }
// }

const updateRating = (commentId, rating, comments, setComments) => {
  let commentsClone = JSON.parse(JSON.stringify(comments));
  commentsClone = commentsClone.map((comment) => {
    if (comment.id === commentId) {
      comment.rating += rating;
    }
    if (comment.replies && comment.replies.length > 0) {
      comment.replies = comment.replies.map((reply) => {
        if (reply.id === commentId) {
          reply.rating += rating;
        }
        return { ...reply };
      });
    }
    return { ...comment };
  });

  // console.log(commentsClone);
  setComments(commentsClone);
};

const ReCaptchaContainer = ({ onCaptchaEntered, setText, reCaptchaRef }) => {
  return (
    <div>
      <div
        style={{
          zIndex: 163,
          position: "fixed",
          top: "50%",
          left: "50%",
          marginLeft: "-152px",
        }}
      >
        <ReCAPTCHA
          ref={reCaptchaRef}
          onChange={onCaptchaEntered}
          sitekey="6Lepq-kZAAAAAGKf0lT5KHuUS3sQDYnuc77NZijB"
        />
      </div>

      <div
        onClick={() => {
          setText("");
        }}
        style={{
          background: "rgba(0,0,0,0.8)",
          zIndex: 162,
          position: "fixed",
          top: "0",
          left: "0",
          right: "0",
          bottom: "0",
        }}
      />
    </div>
  );
};

const addComment = (newComment, comments, setComments) => {
  let commentsClone = JSON.parse(JSON.stringify(comments));

  if (newComment.parentCommentId) {
    commentsClone = commentsClone.map((comment) => {
      if (comment.id === newComment.parentCommentId) {
        if (comment.replies === undefined) comment.replies = [];
        comment.replies.push(newComment);
      }
      return { ...comment };
    });
  } else {
    commentsClone.push(newComment);
  }

  setComments(commentsClone);
};

const deleteComment = (commentId, comments, setComments) => {
  const commentsClone = JSON.parse(JSON.stringify(comments));
  const newCommentsArr = [];
  commentsClone.forEach((comment) => {
    if (comment.id !== commentId) {
      const { replies, ...rest } = comment;
      const repliesNewArr = [];
      if (replies && replies.length > 0) {
        replies.forEach((reply) => {
          if (reply.id !== commentId) {
            repliesNewArr.push(reply);
          }
        });
        rest.replies = repliesNewArr;
      }
      newCommentsArr.push(rest);
    }
  });
  // console.log(commentsClone);
  setComments(newCommentsArr);
};

const CommentForm = (props) => {
  const {
    parentCommentId,
    postUid,
    comments,
    setComments,
    isScrollToComment = false,
    isFormCollapsed: isFormCollapsedProp = false,
  } = props;
  const ref = useRef(null);
  const [appState, appDispatch] = useContext(AppContext);
  const [isFormCollapsed, setFormCollapsed] = useState(isFormCollapsedProp);
  const reCaptchaRef = useRef(null);
  const [text, setText] = useState("");

  const onCaptchaEntered = (value) => {
    const comment = ref.current.innerHTML;
    // .replace(/<br\s*[/]?>/gi, "\n")
    // .replace(/<div class="popup">.*?<\/div>/gi, "");
    // console.log("comment--->", parentCommentId, comment);

    axios
      .post("/api/comments", {
        comment,
        postUid,
        ...(parentCommentId && { parentCommentId }),
        reCaptcha: reCaptchaRef.current.getValue(),
      })
      .then((response) => {
        console.log("ok->", response);
        // loadComments();

        const { id, comment, userId, createdAt, parentCommentId, rating } =
          response.data;

        const newComment = {
          id,
          comment,
          userId,
          createdAt,
          parentCommentId,
          rating,
          userName:
            appState.user && appState.user.name ? appState.user.name : "You",
          picture:
            appState.user && appState.user.picture
              ? appState.user.picture
              : null,
          userCreatedAt: "2020-12-06T17:56:42.000Z",
          highlight: true,
        };

        addComment(newComment, comments, setComments);

        if (isScrollToComment) {
          setTimeout(
            (id) => {
              const addedComment = document.getElementById(`comment${id}`);
              if (addedComment) {
                addedComment.scrollIntoView();
              }
            },
            500,
            response.data.id
          );
        }
        // // window.location.hash = `#${}`;
        ref.current.innerHTML = "";
        // window.ga("send", "event", "Comment", `AddedComment`, `AddedComment`);
      })
      .catch((err) => {
        // window.ga("send", "event", "Comment", `Error-AddingComment`, `Error-AddingComment`);
        console.log("error->", err);
      })
      .finally(() => {
        // reCaptchaRef.current!.reset();
        // setHasLoaded(true);
        setText("");
      });
  };

  const postComment = (e) => {
    e.preventDefault();

    if (appState.user) {
      const comment = ref.current.innerHTML;
      setText(comment);
    } else {
      appDispatch({
        popup: "sign-in",
      });
    }
  };

  return (
    <>
      {text && (
        <ReCaptchaContainer
          onCaptchaEntered={onCaptchaEntered}
          setText={setText}
          reCaptchaRef={reCaptchaRef}
        />
      )}
      <a
        href="#show-comment-form"
        rel="nofollow"
        onClick={(e) => {
          e.preventDefault();
          setFormCollapsed(false);
          setTimeout(() => {
            ref.current.focus();
          }, 500);
        }}
        className={`${isFormCollapsed ? "collapsedCommentForm" : "hideObj"}`}
      >
        Write your comment here
      </a>

      <div className={`${isFormCollapsed ? "hideObj" : "commentForm"}`}>
        <div
          ref={ref}
          className="textarea"
          placeholder="Write your comment here"
          contentEditable
          onPaste={(e) => {
            e.preventDefault();
            const text = e.clipboardData.getData("text/plain");
            document.execCommand("insertHTML", false, text);
          }}
          onBlur={() => {
            console.log("window.getSelection ->", window.getSelection());
            if (
              isFormCollapsedProp &&
              typeof window.getSelection === "undefined"
            ) {
              setFormCollapsed(true);
            }
          }}
        />
        <div className="textModifiers">
          <a
            href="#bold-text"
            rel="nofollow"
            onClick={(e) => {
              e.preventDefault();
              document.execCommand("bold", false, null);
            }}
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="bold"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
            >
              <path
                fill="currentColor"
                d="M333.49 238a122 122 0 0 0 27-65.21C367.87 96.49 308 32 233.42 32H34a16 16 0 0 0-16 16v48a16 16 0 0 0 16 16h31.87v288H34a16 16 0 0 0-16 16v48a16 16 0 0 0 16 16h209.32c70.8 0 134.14-51.75 141-122.4 4.74-48.45-16.39-92.06-50.83-119.6zM145.66 112h87.76a48 48 0 0 1 0 96h-87.76zm87.76 288h-87.76V288h87.76a56 56 0 0 1 0 112z"
              />
            </svg>
          </a>
          <a
            href="#bold-text"
            rel="nofollow"
            onClick={(e) => {
              e.preventDefault();
              document.execCommand("italic", false, null);
            }}
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="italic"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
            >
              <path
                fill="currentColor"
                d="M320 48v32a16 16 0 0 1-16 16h-62.76l-80 320H208a16 16 0 0 1 16 16v32a16 16 0 0 1-16 16H16a16 16 0 0 1-16-16v-32a16 16 0 0 1 16-16h62.76l80-320H112a16 16 0 0 1-16-16V48a16 16 0 0 1 16-16h192a16 16 0 0 1 16 16z"
              />
            </svg>
          </a>
          <a
            href="#bold-text"
            rel="nofollow"
            onClick={(e) => {
              e.preventDefault();
              document.execCommand("underline", false, null);
            }}
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="underline"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                fill="currentColor"
                d="M32 64h32v160c0 88.22 71.78 160 160 160s160-71.78 160-160V64h32a16 16 0 0 0 16-16V16a16 16 0 0 0-16-16H272a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h32v160a80 80 0 0 1-160 0V64h32a16 16 0 0 0 16-16V16a16 16 0 0 0-16-16H32a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16zm400 384H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16z"
              />
            </svg>
          </a>
          <a
            href="#bold-text"
            rel="nofollow"
            onClick={(e) => {
              e.preventDefault();
              document.execCommand("strikeThrough", false, null);
            }}
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="strikethrough"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="currentColor"
                d="M496 224H293.9l-87.17-26.83A43.55 43.55 0 0 1 219.55 112h66.79A49.89 49.89 0 0 1 331 139.58a16 16 0 0 0 21.46 7.15l42.94-21.47a16 16 0 0 0 7.16-21.46l-.53-1A128 128 0 0 0 287.51 32h-68a123.68 123.68 0 0 0-123 135.64c2 20.89 10.1 39.83 21.78 56.36H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h480a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm-180.24 96A43 43 0 0 1 336 356.45 43.59 43.59 0 0 1 292.45 400h-66.79A49.89 49.89 0 0 1 181 372.42a16 16 0 0 0-21.46-7.15l-42.94 21.47a16 16 0 0 0-7.16 21.46l.53 1A128 128 0 0 0 224.49 480h68a123.68 123.68 0 0 0 123-135.64 114.25 114.25 0 0 0-5.34-24.36z"
              />
            </svg>
          </a>
        </div>
        <a
          href="#submit-comment"
          rel="nofollow"
          className="submitBtn"
          onClick={postComment}
        >
          Submit Comment
        </a>
      </div>
    </>
  );
};

const Comment = (props) => {
  const {
    item,
    postUid,
    loadComments,
    comments,
    setComments,
    setCommentIdToDelete,
    setCommentIdToReport,
    previewMode,
  } = props;
  const [appState, appDispatch] = useContext(AppContext);
  const [isShowCommentFormVisible, setShowCommentFormVisible] = useState(false);

  const setRating = (rating) => {
    if (appState.user) {
      axios
        .post("/api/comments/rate", {
          commentId: item.id,
          rating,
        })
        .then((response) => {
          // console.log("rating response->", response);
          const { insertId, affectedRows } = response.data;

          if (insertId > 0) {
            if (affectedRows === 1) {
              // inserted
              updateRating(item.id, rating, comments, setComments);
            } else if (affectedRows === 2) {
              // update
              updateRating(item.id, 2 * rating, comments, setComments);
            }
          }
          // loadComments();

          // ref.current.innerHTML = "";
          // window.ga("send", "event", "Comment", `SetRating`, `SetRating`);
        })
        .catch((err) => {
          // window.ga("send", "event", "Comment", `Error-Rating`, `Error-Rating`);
          console.log("error->", err);
        })
        .finally(() => {
          // reCaptchaRef.current!.reset();
          // setHasLoaded(true);
          // setText("");
        });
    } else {
      appDispatch({
        popup: "sign-in",
      });
    }
  };

  let ratingStatus = "";
  if (item.rating > 0) {
    ratingStatus = "positiveRating";
  } else if (item.rating < 0) {
    ratingStatus = "negativeRating";
  }
  return (
    <>
      <div
        className={item.highlight ? "highlightComment comment" : "comment"}
        id={`comment${item.id}`}
        key={item.id}
      >
        <div className="commentHeader">
          <div
            className="avatar"
            style={{
              backgroundImage: `url(${
                item.picture ? item.picture : "/static/img/profile.svg"
              })`,
              opacity: item.picture ? 1 : 0.4,
            }}
          />
          <div className="commentAuthor">
            {item.userName ? item.userName : `User${item.userId}`}
          </div>
          <div className="commentDate">
            <span className="desktopDateView">
              {String(new Date(item.createdAt).toLocaleString())} • ({" "}
            </span>
            {timeSince(item.createdAt)} ago
            <span className="desktopDateView"> )</span>
          </div>

          <div className="commentRateWrapper">
            {item.replies === undefined && postUid && (
              <a
                href="#reply"
                rel="nofollow"
                className="replyComment"
                onClick={(e) => {
                  e.preventDefault();
                  if (previewMode) {
                    if (item.postUid === "0n") {
                      goToLink("/");
                      //</div> "Home Page"
                    } else if (item.postUid === "Kw") {
                      goToLink("/speaking/speaking-club");
                      // ? "Speaking Club"
                    } else if (item.postUid === "X5PDq") {
                      goToLink("/speaking/simulator");
                      // ? "Speaking Club"
                    } else if (item.postUid === "EbB") {
                      window.location = "https://ielts69.com/draft";
                      // ? "Free Checker"
                    } else {
                      goToLink(`/catalog/writing/${item.postUid}`);
                    }
                  } else {
                    setShowCommentFormVisible(!isShowCommentFormVisible);
                  }
                }}
              >
                <svg
                  viewBox="0 -61 511.9997 511"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M234.667,170.844V96c0-17.646-14.354-32-32-32c-7.938,0-15.604,3.042-21.542,8.563l-13.771,12.74
			C122.042,127.229,46.188,197.396,7.375,236.323C2.625,241.115,0,248.094,0,256s2.625,14.885,7.375,19.688
			c38.813,38.917,114.667,109.083,159.979,151.01l13.771,12.74c5.938,5.521,13.604,8.563,21.542,8.563c17.646,0,32-14.354,32-32
			v-74.594c153.604,2.156,256,50.604,256,95.927c0,5.896,4.771,10.667,10.667,10.667c5.896,0,10.667-4.771,10.667-10.667
			C512,293.594,388.563,176.052,234.667,170.844z M224,320c-5.896,0-10.667,4.771-10.667,10.667V416
			c0,5.885-4.792,10.667-10.667,10.667c-3.25,0-5.625-1.563-7.042-2.875l-13.771-12.75C136.646,369.219,61,299.24,22.5,260.635
			c-0.583-0.583-1.167-2.25-1.167-4.635s0.583-4.052,1.167-4.625C61,212.76,136.646,142.781,181.854,100.958l13.771-12.75
			c1.417-1.313,3.792-2.875,7.042-2.875c5.875,0,10.667,4.781,10.667,10.667v85.333c0,5.896,4.771,10.667,10.667,10.667
			c128.25,0,235.646,83.729,261,194.885C439.479,347.01,340.917,320,224,320z"
                  />
                </svg>
              </a>
            )}

            <a
              href="#thumbs-down"
              rel="nofollow"
              onClick={(e) => {
                e.preventDefault();
                if (appState.user) {
                  setRating(-1);
                } else {
                  appDispatch({
                    popup: "sign-in",
                  });
                }
              }}
              className="thumbsDown"
            >
              <svg
                viewBox="0 0 24 24"
                preserveAspectRatio="xMidYMid meet"
                focusable="false"
              >
                <g>
                  <path
                    style={{ fill: "#8d96b2" }}
                    d="M17,4h-1H6.57C5.5,4,4.59,4.67,4.38,5.61l-1.34,6C2.77,12.85,3.82,14,5.23,14h4.23l-1.52,4.94C7.62,19.97,8.46,21,9.62,21 c0.58,0,1.14-0.24,1.52-0.65L17,14h4V4H17z M10.4,19.67C10.21,19.88,9.92,20,9.62,20c-0.26,0-0.5-0.11-0.63-0.3 c-0.07-0.1-0.15-0.26-0.09-0.47l1.52-4.94l0.4-1.29H9.46H5.23c-0.41,0-0.8-0.17-1.03-0.46c-0.12-0.15-0.25-0.4-0.18-0.72l1.34-6 C5.46,5.35,5.97,5,6.57,5H16v8.61L10.4,19.67z M20,13h-3V5h3V13z"
                  ></path>
                </g>
              </svg>
            </a>
            <div className={`currentRating ${ratingStatus}`}>{item.rating}</div>
            <a
              href="#thumbs-up"
              rel="nofollow"
              onClick={(e) => {
                e.preventDefault();
                if (appState.user) {
                  setRating(1);
                } else {
                  appDispatch({
                    popup: "sign-in",
                  });
                }
              }}
              className="thumbsUp"
            >
              <svg
                viewBox="0 0 24 24"
                preserveAspectRatio="xMidYMid meet"
                focusable="false"
              >
                <g>
                  <path
                    style={{ fill: "#8d96b2" }}
                    d="M18.77,11h-4.23l1.52-4.94C16.38,5.03,15.54,4,14.38,4c-0.58,0-1.14,0.24-1.52,0.65L7,11H3v10h4h1h9.43 c1.06,0,1.98-0.67,2.19-1.61l1.34-6C21.23,12.15,20.18,11,18.77,11z M7,20H4v-8h3V20z M19.98,13.17l-1.34,6 C18.54,19.65,18.03,20,17.43,20H8v-8.61l5.6-6.06C13.79,5.12,14.08,5,14.38,5c0.26,0,0.5,0.11,0.63,0.3 c0.07,0.1,0.15,0.26,0.09,0.47l-1.52,4.94L13.18,12h1.35h4.23c0.41,0,0.8,0.17,1.03,0.46C19.92,12.61,20.05,12.86,19.98,13.17z"
                  ></path>
                </g>
              </svg>
            </a>
          </div>
        </div>
        {previewMode && (
          <div
            style={{
              padding: "2px 6px",
              color: "#fff",
              fontSize: "14px",
              fontWeight: "bold",
              backgroundColor: "#3f50b5",
              display: "inline-block",
              marginTop: "10px",
              borderRadius: "5px",
            }}
          >
            {item.postUid === "0n"
              ? "Home Page"
              : item.postUid === "X5PDq"
              ? "Speaking Simulator"
              : item.postUid === "Kw"
              ? "Speaking Club"
              : item.postUid === "EbB"
              ? "Free Checker"
              : `Writing - ${item.subject.substring(0, 60)}...`}
          </div>
        )}
        <div
          className="commentContent"
          dangerouslySetInnerHTML={{ __html: item.comment }}
        />
        {appState.user &&
          (appState.user.id === item.userId || appState.user.id === 2) && (
            <a
              href="#delete"
              onClick={(e) => {
                e.preventDefault();
                setCommentIdToDelete(item.id);
              }}
              className="deleteComment"
            >
              delete
            </a>
          )}
        {window.cordova && (
          <a
            href="#report"
            onClick={(e) => {
              e.preventDefault();
              setCommentIdToReport(item.id);
            }}
            className="deleteComment"
          >
            Report Comment
          </a>
        )}
      </div>
      {item.replies === undefined && isShowCommentFormVisible && (
        <div className="repliesContainer">
          <CommentForm
            parentCommentId={item.id}
            postUid={postUid}
            loadComments={loadComments}
            comments={comments}
            setComments={setComments}
          />
        </div>
      )}
    </>
  );
};

const Comments = ({
  comments,
  setComments,
  postUid,
  loadComments,
  commentsSorting,
  setCommentsSorting,
  previewMode = false,
  preloader = false,
}) => {
  const [commentIdToDelete, setCommentIdToDelete] = useState(null);
  const [commentIdToReport, setCommentIdToReport] = useState(null);

  // const totalComments = Object.keys(comments).length;

  if (comments === null && preloader === true) {
    return <CommentsPreloader />;
  } else {
    const commentsBlock = comments.map((item) => {
      return (
        <div key={item.id}>
          <Comment
            item={item}
            loadComments={loadComments}
            postUid={postUid}
            comments={comments}
            setComments={setComments}
            setCommentIdToDelete={setCommentIdToDelete}
            setCommentIdToReport={setCommentIdToReport}
            previewMode={previewMode}
          />
          {item.replies && (
            <div className="repliesContainer">
              {item.replies.map((el) => (
                <Comment
                  item={el}
                  key={el.id}
                  comments={comments}
                  setComments={setComments}
                  setCommentIdToDelete={setCommentIdToDelete}
                />
              ))}
              <CommentForm
                postUid={postUid}
                loadComments={loadComments}
                parentCommentId={item.id}
                isFormCollapsed
                comments={comments}
                setComments={setComments}
              />
            </div>
          )}
        </div>
      );
    });

    let totalComments = 0;
    comments.forEach((item) => {
      totalComments += 1;
      if (item.replies && item.replies.length > 0) {
        item.replies.forEach(() => {
          totalComments += 1;
        });
      }
    });
    return (
      <>
        {!previewMode && (
          <div id="comments" className="commentsHeader">
            Comments <b>{totalComments}</b>
            <ul
              className="tabulator"
              style={{
                float: "right",
              }}
            >
              <li
                className={`tab-item ${
                  commentsSorting === "oldest" ? "active" : ""
                }`}
              >
                <a
                  className="tab-link"
                  href="#oldest"
                  rel="nofollow"
                  onClick={(e) => {
                    e.preventDefault();
                    setCommentsSorting("oldest");
                  }}
                >
                  Oldest
                </a>
              </li>
              <li
                className={`tab-item ${
                  commentsSorting === "latest" ? "active" : ""
                }`}
              >
                <a
                  className="tab-link"
                  href="#latest"
                  rel="nofollow"
                  onClick={(e) => {
                    e.preventDefault();
                    setCommentsSorting("latest");
                  }}
                >
                  Latest
                </a>
              </li>
              <li
                className={`tab-item ${
                  commentsSorting === "votes" ? "active" : ""
                }`}
              >
                <a
                  className="tab-link"
                  href="#votes"
                  rel="nofollow"
                  onClick={(e) => {
                    e.preventDefault();
                    setCommentsSorting("votes");
                  }}
                >
                  Votes
                </a>
              </li>
            </ul>
          </div>
        )}
        {totalComments > 10 && !previewMode && (
          <CommentForm
            postUid={postUid}
            loadComments={loadComments}
            comments={comments}
            setComments={setComments}
            isScrollToComment
          />
        )}
        {commentsBlock}

        {!previewMode && (
          <CommentForm
            postUid={postUid}
            loadComments={loadComments}
            comments={comments}
            setComments={setComments}
          />
        )}
        {commentIdToDelete && (
          <div className="fixedOverlay">
            <style jsx>{`
              .fixedOverlay {
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: rgba(0, 0, 0, 0.8);
                position: fixed;
                display: flex;
                z-index: 10000;
                align-items: center;
                justify-content: center;
              }
              .fixedOverlay .fixedPopup {
                width: auto;
                min-width: 300px;
                background-color: #fff;
                border-radius: 5px;
                padding: 35px;
                position: relative;
                box-sizing: border-box;
              }
              .fixedOverlay .fixedPopup .title {
                color: #2b2d38;
                font-size: 21px;
                text-align: center;
                line-height: 22px;
                padding-bottom: 24px;
              }
              .fixedOverlay .fixedPopup .closeBtn {
                position: absolute;
                top: 10px;
                right: 10px;
                width: 20px;
                height: 20px;
                cursor: pointer;
                text-decoration: none;
                color: #000;
              }
              .fixedOverlay .fixedPopup .closeBtn svg {
                width: 20px;
                height: 20px;
              }
              .fixedOverlay .fixedPopup .btn {
                background-color: #15c39a;
                background: linear-gradient(90deg, #15c39a, #17d9aa);
                color: #fff;
                letter-spacing: 0.8px;
                padding: 0 19px;
                align-items: center;
                text-transform: uppercase;
                font-size: 14px;
                height: 32px;
                text-align: center;
                display: inline-block;
                text-decoration: none;
                line-height: 32px;
                position: relative;
                border-radius: 23px;
                font-weight: bold;
              }
              .fixedOverlay .fixedPopup .btn.red {
                background: none;
                background-color: #e31837 !important;
              }
              .fixedOverlay .fixedPopup .btn.grey {
                background: none;
                background-color: #444 !important;
                color: #fff !important;
              }
              .fixedOverlay .fixedPopup .btn.floatRight {
                float: right;
              }
              .fixedOverlay .fixedPopup .btn.floatLeft {
                float: left;
              }
            `}</style>
            <div className="fixedPopup">
              <a
                href="#close"
                onClick={(e) => {
                  e.preventDefault();
                  setCommentIdToDelete(null);
                }}
                className="closeBtn"
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="times"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 352 512"
                >
                  <path
                    fill="currentColor"
                    d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                  />
                </svg>
              </a>
              <div className="title">Delete comment?</div>
              <div className="clearfix">
                <a
                  href="#delete"
                  onClick={(e) => {
                    e.preventDefault();

                    axios
                      .delete(`/api/comments/${commentIdToDelete}`)
                      .then((response) => {
                        if (response.data.status.changedRows) {
                          deleteComment(
                            commentIdToDelete,
                            comments,
                            setComments
                          );
                        }
                      });

                    setCommentIdToDelete(null);
                  }}
                  className="btn red floatLeft"
                >
                  delete
                </a>
                <a
                  href="#cancel"
                  onClick={(e) => {
                    e.preventDefault();
                    setCommentIdToDelete(null);
                  }}
                  className="btn grey floatRight"
                >
                  cancel
                </a>
              </div>
            </div>
          </div>
        )}
        {commentIdToReport && (
          <div className="fixedOverlay">
            <style jsx>{`
              .fixedOverlay {
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: rgba(0, 0, 0, 0.8);
                position: fixed;
                display: flex;
                z-index: 10000;
                align-items: center;
                justify-content: center;
              }
              .fixedOverlay .fixedPopup {
                width: auto;
                min-width: 300px;
                background-color: #fff;
                border-radius: 5px;
                padding: 35px;
                position: relative;
                box-sizing: border-box;
              }
              .fixedOverlay .fixedPopup .title {
                color: #2b2d38;
                font-size: 21px;
                text-align: center;
                line-height: 22px;
                padding-bottom: 24px;
              }
              .fixedOverlay .fixedPopup .closeBtn {
                position: absolute;
                top: 10px;
                right: 10px;
                width: 20px;
                height: 20px;
                cursor: pointer;
                text-decoration: none;
                color: #000;
              }
              .fixedOverlay .fixedPopup .closeBtn svg {
                width: 20px;
                height: 20px;
              }
              .fixedOverlay .fixedPopup .btn {
                background-color: #15c39a;
                background: linear-gradient(90deg, #15c39a, #17d9aa);
                color: #fff;
                letter-spacing: 0.8px;
                padding: 0 19px;
                align-items: center;
                text-transform: uppercase;
                font-size: 14px;
                height: 32px;
                text-align: center;
                display: inline-block;
                text-decoration: none;
                line-height: 32px;
                position: relative;
                border-radius: 23px;
                font-weight: bold;
              }
              .fixedOverlay .fixedPopup .btn.red {
                background: none;
                background-color: #e31837 !important;
              }
              .fixedOverlay .fixedPopup .btn.grey {
                background: none;
                background-color: #444 !important;
                color: #fff !important;
              }
              .fixedOverlay .fixedPopup .btn.floatRight {
                float: right;
              }
              .fixedOverlay .fixedPopup .btn.floatLeft {
                float: left;
              }
            `}</style>
            <div className="fixedPopup">
              <a
                href="#close"
                onClick={(e) => {
                  e.preventDefault();
                  setCommentIdToReport(null);
                }}
                className="closeBtn"
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="times"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 352 512"
                >
                  <path
                    fill="currentColor"
                    d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                  />
                </svg>
              </a>
              <div className="title">Report comment?</div>
              <div className="clearfix">
                <a
                  href="#delete"
                  onClick={(e) => {
                    e.preventDefault();

                    axios
                      .post(`/api/comments/report/${commentIdToReport}`)
                      .then((response) => {
                        if (response.data) {
                          deleteComment(commentIdToReport);
                        }
                      });

                    setCommentIdToReport(null);
                  }}
                  className="btn red floatLeft"
                >
                  report
                </a>
                <a
                  href="#cancel"
                  onClick={(e) => {
                    e.preventDefault();
                    setCommentIdToReport(null);
                  }}
                  className="btn grey floatRight"
                >
                  cancel
                </a>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
};

const CommentsPreloader = () => {
  const skeleton = [...Array(10)].map((el, index) => (
    <li
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        padding: "0 0 40px",
      }}
      key={index}
    >
      <style jsx>{`
        .skeleton {
          position: relative;
          overflow: hidden;
          background: #ccc;
        }
        .skeleton::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(90deg, #ccc, #dedede, #ccc);
          animation: progress 1s ease-in-out infinite;
        }
        @keyframes progress {
          0% {
            transform: translate3d(-100%, 0, 0);
          }
          100% {
            transform: translate3d(100%, 0, 0);
          }
        }
      `}</style>
      <div style={{ display: "flex", width: "100%" }}>
        <div
          style={{
            minWidth: "40px",
            height: "40px",
            backgroundColor: "#ccc",
            borderRadius: "25px",
          }}
        />
        <div
          style={{
            marginLeft: "10px",
            height: "40px",
            width: "100%",
            borderRadius: "5px",
          }}
          className="skeleton"
        />
      </div>
      <div
        style={{
          marginTop: "10px",
          display: "flex",
          height: "15px",
          width: "100%",
          borderRadius: "5px",
        }}
        className="skeleton"
      />
      <div
        style={{
          marginTop: "6px",
          display: "flex",
          height: "15px",
          width: "100%",
          borderRadius: "5px",
        }}
        className="skeleton"
      />
      <div
        style={{
          marginTop: "6px",
          display: "flex",
          height: "15px",
          width: "100%",
          borderRadius: "5px",
        }}
        className="skeleton"
      />
    </li>
  ));

  const commentsContainer = (
    <ul className="preloader" style={{ padding: "0 15px" }}>
      {skeleton}
    </ul>
  );

  return (
    <div id="comments" className="commentsHeader commentsPreloader">
      Loading comments...
      <div className="commentForm skeleton commentFormPreloader"></div>
      {commentsContainer}
    </div>
  );
};

export default Comments;

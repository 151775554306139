import { useContext, useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import React from "react";
import { AppContext } from "../../context";
import axios from "../../axios";
import Pagination from "../parts/Pagination";

const IELTSVocabulary = () => {
  const history = useHistory();
  const perPage = 20;
  const { band, page = 1 } = useParams();
  const [data, setDate] = useState(null);

  useEffect(async () => {
    const res = await axios.get(
      `/api/tests/vocabulary?type=band-${band}&skip=${
        perPage * (page - 1)
      }&take=${perPage}`
    );
    const data = res.data;
    /*
skip: 20
take: 20
total: 310
*/

    const PagingInfo = {
      current_page: data.skip / perPage + 1,
      total_pages: Math.ceil(data.total / perPage),
      total_records: data.total,
      records_per_page: perPage,
    };
    setDate({
      ...data,
      PagingInfo,
    });
    console.log("=====>", data);
  }, [page, band]);

  console.log("DATA =======>", data);
  return (
    <div className="welcomeScreen">
      <div className="landingScreen">
        <h1>
          <b>Band {band}</b>
        </h1>
        <div>
          <style>{`
                .vocabularyTable{
                    border-spacing: 2px;
                    border-collapse: separate;
                    font-size: 20px;
                    text-align: left;
                    width: 100%;
                    letter-spacing: 1px;
                }

                .vocabularyTable td{ 
                    padding: 7px 12px;
                    background-color: #eee;
                }
                .vocabularyTable .tableHeader td{
                    background-color: #5d5d5d;
                    color: #fff;
                }
            `}</style>
          <table border-spacing="1" className="vocabularyTable">
            <tr className="tableHeader">
              <td>Id</td>
              <td>Word</td>
              <td>Paraphrases (Synonyms / Antonyms)</td>
            </tr>

            {data && data.result ? (
              data.result.map((row) => (
                <tr>
                  <td>{row.id}</td>
                  <td>{row.FIELD1}</td>
                  <td>{row.FIELD2}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={3}>Loading...</td>
              </tr>
            )}
          </table>

          {data && (
            <Pagination
              pagingInfo={data.PagingInfo}
              url={`/ielts-vocabulary/${band}`}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default IELTSVocabulary;

import axios from "axios";
import { getDomain } from "./utils";

const fetchClient = () => {
  let baseURL = getDomain();

  const defaultOptions = {
    baseURL,
    headers: {
      "Content-Type": "application/json",
    },
  };

  // Create instance
  const instance = axios.create(defaultOptions);

  // Set the AUTH token for any request
  instance.interceptors.request.use((config) => {
    // @ts-ignore
    const jwt = localStorage.getItem("_IELTS69_JWT_");
    const token = jwt || "";
    config.headers.Authorization = token !== "" ? `Bearer ${token}` : "";
    return config;
  });

  return instance;
};

export default fetchClient();
